@import '../../styles/common';
.lit-modal-informationPane {
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	left: 0;
	top: 6%;
	background-color: transparent;
	width: 100%;
	height: 100%;

	margin: auto;
	-webkit-overflow-scrolling: touch;
	z-index: $zModal;
	animation: fade 0.5s;
}
.lit-modal-pane {
	background: linear-gradient(to bottom right, #124779, #147997);
	padding: 10px;
	max-width: 40%;
	margin: 15% auto;
	animation: slideUp 0.7s;
	border: thin solid rgb(2, 53, 63);
	border-radius: 5px;
	color: white;
	box-shadow: 0 0 13px 3px rgb(10, 7, 7);
}
.lit-option-pane-container {
	padding: 35px;
}
.lit-options-container {
	display: flex;
	margin: 5px;

	padding: 10px;
	.lit-option-pane {
		margin: 4px;
		padding: 1px;
		border-radius: 5px;
		background-color: rgb(0, 255, 242);
		box-shadow: 0 0 5px 3px rgb(46, 46, 46);
		width: 7rem;

		.__approve {
			margin: auto;
			background-color: rgb(64, 158, 84);
			color: white;
			border: thin solid white;
			font-size: medium;
			font-family: 'Roboto', Arial, sans-serif;
			width: 100%;
			height: 30px;
		}
		.__approve:hover {
			margin: auto;
			background-color: rgb(9, 148, 39);
			color: white;
			border: thin solid white;
			font-size: medium;
			font-family: 'Roboto', Arial, sans-serif;
			width: 100%;
			height: 30px;
		}

		.__disprove {
			margin: auto;
			background-color: rgb(192, 85, 35);
			box-shadow: 0 0 5px 3px rgb(46, 46, 46);
			border: thin solid white;
			color: white;
			font-size: medium;
			font-family: 'Roboto', Arial, sans-serif;
			width: 100%;
			height: 30px;
		}
		.__disprove:hover {
			margin: auto;
			background-color: rgb(175, 78, 22);
			border: thin solid white;
			color: white;
			font-size: medium;
			font-family: 'Roboto', Arial, sans-serif;
			width: 100%;
			height: 30px;
		}
	}
}
