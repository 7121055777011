.lit-page-title,
.lit-page-lead {
	max-width: 700px;
	animation: fade 0.8s;
}

.lit-page-title {
	margin: 0;
}

.lit-page-lead {
	margin: $space-sm 0 $space;
	font-size: $p;
}
