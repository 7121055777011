.environment-banner{
    z-index:1000;
    position: sticky;
    top:0;
    height: 35px;
    background-color:rgb(237, 172, 115);
    display: none;
    justify-content: center;
    align-items: center;
    //left: 0;
    //top: 0;
    //right: 0;

    &.development{
        background-color:rgb(237, 115, 115);
        display: flex;
    }

    &.testing{
        background-color:rgb(237, 172, 115);
        display: flex;
    }
}
