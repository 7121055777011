@import './vars/colors';
@import './vars/breakpoints';
@import './vars/environment';

.primary {
	color: $primary;
}
.secondary {
	color: $secondary;
}

.bg-interactive {
	@include bgColor($interactiveColor);
}
.bg-primary {
	@include bgColor($primary);
}
.bg-secondary {
	@include bgColor($secondary);
}
.bg-tertiary {
	@include bgColor($tertiary);
}
.bg-positive {
	@include bgColor($positiveColor);
}
.bg-negative {
	@include bgColor($negativeColor);
}
.bg-danger {
	@include bgColor($red);
}
.bg-white {
	@include bgColor($white);
}
.bg-offwhite {
	@include bgColor($offwhite);
}
.bg-light-gray {
	@include bgColor($lightGray);
}
.bg-gray {
	@include bgColor($gray);
}
.bg-black {
	@include bgColor($black);
}
.bg-purple {
	@include bgColor($purple);
}
.bg-transparent {
	background: transparent;
}
.bg-text {
	@include bgColor($textColor);
}
.bg-darken {
	@include bgColor(rgba(0, 0, 0, 0.1));
}
.bg-teal {
	@include bgColor($teal);
}
.bg-green {
	@include bgColor($green);
}
.bg-orange {
	@include bgColor($orange);
}

/* Text and border colors */
.white {
	color: $white;
}
