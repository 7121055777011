.lit-view {
	//margin-top: $headerHeight;
	margin-left: $sidebarWidth;
	//box-shadow: $boxShadow2;
	// border-radius: $borderRadius 0 0 0;
	position: relative;
	z-index: ($zSidebar + 1);
	&__content {
		min-height: calc(100vh - #{$headerHeight});
		padding: $space $space;
		background-color: $offwhite;
	}
}
