@keyframes fade {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes slideUp {
	from {
		opacity: 0;
		transform: translateY(8px);
		transform: translate3d(0, 8px, 0);
	}
	to {
		opacity: 1;
		transform: translateY(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
@keyframes glowing {
	0% {
		background-color: #2ba805;
		box-shadow: 0 0 5px #2ba805;
	}
	50% {
		background-color: #60e638;
		box-shadow: 0 0 20px #49e819;
	}
	100% {
		background-color: #2ba805;
		box-shadow: 0 0 5px #2ba805;
	}
}

@keyframes slideDown {
	from {
		opacity: 0;
		transform: translateY(-8px);
		transform: translate3d(0, -8px, 0);
	}
	to {
		opacity: 1;
		transform: translateY(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes scaleIn {
	from {
		opacity: 0;
		transform: scale(0.95);
	}
	to {
		opacity: 1;
		transform: scale(1);
	}
}
