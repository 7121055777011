@import '../../styles/common.scss';

.bank-id-iframe-wrapper {
	min-width: 350px;
	max-width: 700px;
}

.lit-login {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	margin-top: 50px;

	& .bank-id-iframe-wrapper {
		margin: 0 auto;
	}

	&__header {
		font-size: 30pxn;
		margin: $space-sm 0;
	}
	&__description {
		margin: $space-sm 0;
		color: $textColor;
	}
	&__wrapper {
		box-shadow: $boxShadow1;
		display: inline-block;
		margin: 0 auto;
		vertical-align: top;
		max-width: 680px;
		min-width: 360px;
		min-height: 230px;
		padding: $space-m $space-lg;
		position: relative;

		&--dark {
			color: $white;
		}
	}

	&__form {
		width: 100%;
		display: block;
		font-size: 16px;

		&__label {
			display: inline-block;
			width: 40%;
		}
		&__input {
			box-shadow: $boxShadow1;
			border-radius: 2px;
			border: none;
			margin-bottom: 0.7em;
			padding: 1em;
			display: inline-block;
			width: 60%;
		}
	}

	&__back-button {
		border: none;
		background: none;
		color: black;
		font-size: 16px;
		display: block;
		margin-bottom: 15px;
		text-align: left;
		min-width: 350px;
		max-width: 700px;
		transition: all 0.2s;

		&:hover {
			color: #347a99;
		}
	}

	@media screen and (max-width: 1080px) {
		&__wrapper {
			width: 100%;
		}
	}
}
.custom-login-iframe-wrapper {
	/* 
	background-color: white; */
	padding-left: 12%;
	width: 80%;
	margin: auto;
	padding: 1%;
	margin-top: -6rem;
}

.lit-register {
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: height 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.05);
	}
	&__fg {
		position: relative;
	}
	&__expanded {
		opacity: 0;
		animation: registerExpand 1.2s cubic-bezier(0.175, 0.885, 0.32, 1.05);
		animation-fill-mode: forwards;
		animation-delay: 0.1s;
		transform-origin: top center;
	}
}

@keyframes registerExpand {
	from {
		opacity: 0;
		transform: scaleY(0.97);
	}
	50% {
		transform: scaleY(1);
	}
	to {
		opacity: 1;
	}
}

.lit-thirdPCsupport {
	margin: auto;
	text-align: left;
	color: red;
	width: 70%;
	font-size: 18px;
}
.lit-thrid-party-settings {
	margin: auto;
	padding: 8px;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
}
