@import 'spacing';

$wideContentWrapperWidth: 1440;
$outerContentWrapperWidth: 1140;
$articleContentWrapperWidth: 680;

// content widths
$widthThin: 400;
$widthBox: 560;

// border radius
$borderRadius: 3px;

// Header
$headerHeight: 76px;
$headerMobileHeight: 40px;

// layers
$zBg: 0;
$zPromote: 1;
$zSidebar: 60;
$zHeader: 70;
$zModal: 80;

// box shadows
$boxShadow4: 0 13px 18px rgba(0,0,0,0.08);
$boxShadow3: 0 13px 10px rgba(0,0,0,0.08);
$boxShadow2: 3px 3px 30px rgba(0,0,0,0.1);
$boxShadow1: 0 15px 20px rgba(0,0,0,0.04);
$boxShadowHover: 0 13px 30px rgba(0, 0, 0, 0.12);

// Timeline dots
$proximitySize: 72px;
$dotSize: $space * 1.5;

// single event
$singleEventWidth: 1200px;

// editor
$sidebarWidth: 200px;

// form controls
$inputWidth: 300;
