@import '../../styles/common';
.lit-view {
	.lit-subscriptions {
		margin-top: -60px;
		nav {
			position: relative;
			display: flex;
		}
		nav a {
			position: relative;
			margin: 0 10px;
			font-size: 20px;
			color: rgb(248, 246, 246);
			font-family: 'Trebuchet MS', sans-serif;
			text-decoration: none;
		}
		nav #marker-default {
			position: absolute;
			left: 10px;
			height: 4px;
			width: 113px;
			background: rgb(248, 246, 246);
			bottom: -8px;
			transition: 0.5s;
			border-radius: 4px;
		}
		nav #marker-information {
			position: absolute;
			left: 141px;
			height: 4px;
			width: 176px;
			background: rgb(253, 252, 252);
			bottom: -8px;
			transition: 0.5s;
			border-radius: 4px;
		}
		nav #marker-groups {
			position: absolute;
			left: 337px;
			height: 4px;
			width: 70px;
			background: rgb(250, 248, 248);
			bottom: -8px;
			transition: 0.5s;
			border-radius: 4px;
		}

		nav #marker-informationDe {
			position: absolute;
			left: 141px;
			height: 4px;
			width: 236px;
			background: rgb(253, 252, 252);
			bottom: -8px;
			transition: 0.5s;
			border-radius: 4px;
		}
		nav #marker-groupsDe {
			position: absolute;
			left: 397px;
			height: 4px;
			width: 70px;
			background: rgb(250, 248, 248);
			bottom: -8px;
			transition: 0.5s;
			border-radius: 4px;
		}

		.info {
			margin: 0 (-$space-sm);
			display: flex;

			/* flex-wrap: wrap; */

			&__col {
				border-radius: $borderRadius;
				display: inline-block;
				vertical-align: top;
				padding: $space-m $space-lg;
				margin: $space-sm;
				text-align: center;
				background-color: $white;
				box-shadow: $boxShadow1;
				flex-wrap: wrap;
				flex-shrink: 0;
				min-width: 340px;
				@include stagger(fade, 4, 0.4);
			}

			&__heading {
				margin: 0 0 $space-sm;
			}

			&__text {
				&.large {
					margin: 0;
					font-size: $h3;
					font-weight: bold;
				}
			}

			&__status {
				// padding: 4px 0 0;

				.lit-test-status__indicator {
					margin-left: -5px;
				}
				.lit-test-status__label {
					color: $textColor;
					font-size: $h3;
					font-weight: bold;
				}
			}

			&__extra {
				margin-top: $space-sm;
				font-size: $small;

				span {
					line-height: 33px;
					color: $gray;
				}
			}
		}

		.see-more {
			margin-top: $space-xlg;
			min-width: 230px;

			&:not(.bg-border) {
				border: 1px solid transparent;
			}
		}
	}
}

.checkout {
	border-bottom: solid rgba(29, 106, 173, 255);
	padding: 1%;
	&__expanded {
		opacity: 0;
		animation: registerExpand 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.05);
		animation-fill-mode: forwards;
		animation-delay: 0.1s;
		transform-origin: top;
	}
}
.lit_eidt-btnGrid {
	margin-top: 10px;
}
.lit_assign-permission {
	display: inline-block;
	float: left;
}
.add-language_bg-secondary {
	height: 45px;
	background-color: #43bbec;
	color: white;
	margin-top: 10px;
	padding-bottom: 10px;
	margin-left: 15px;
	text-align: left;
	text-decoration: whitesmoke;
}
.lit_delete-permission {
	display: inline-block;
}
.delete-permission_bg-secondary {
	height: 45px;
	background-color: #c41212;
	color: white;
	margin-top: 5px;
	padding-bottom: 7px;
	text-align: left;
	text-decoration: whitesmoke;
	margin-left: 7px;
}

.lit-new-subscriptions {
	vertical-align: top;
	max-width: 740px;
	width: 100%;
	padding: $space-m $space-lg;
	margin-left: 410px;
	margin-top: -180px;
	text-align: left;
	background-color: $white;
	box-shadow: $boxShadow1;
	min-width: 440px;
}
.lit-button-grid {
	display: inline-block;
	margin-right: -35px;
	vertical-align: left;
	width: 1080px;
	box-sizing: border-box;

	.lit_show_Subscripton {
		margin-left: 10px;
	}
	.lit-deletedTest-info {
		margin-left: 10px;
	}
}
.lit-main-table {
	margin-top: -2px;
	border: solid black;
	border-collapse: collapse;
	border-width: 2px;

	td,
	th {
		border: solid 2px black;
	}
}

.lit-button-header {
	display: flex;
	align-items: flex-start;
	gap: 10px;
	box-sizing: border-box;
}
.lit-subscription-table-container {
	width: 100%;
}
.lit_tables_container {
	margin-top: 10px;
	display: inline-block;
	.lit-main-master-header {
		color: white;
		background-color: rgb(113, 168, 241);
		.lit-main-language-header {
			min-width: 85px;
			max-width: 85px;
			text-align: center;
		}
		.lit-main-production-header {
			text-align: center;
			font-weight: bold;

			.lit-main_subscription-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}
		.lit-main-norming-header {
			text-align: center;
			font-weight: bold;
			.lit-main_subscription-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}
		.lit-main-basic-header {
			min-width: 86px;
			text-align: center;
			font-weight: bold;
			.lit-main_subscription-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}
		.lit-admin-basic-header {
			min-width: 86px;
			text-align: center;
			border-right: none;
			font-weight: bold;
			.lit-main_subscription-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}
		.lit-main-final-header {
			min-width: 101px;
			text-align: center;
			font-weight: bold;

			.lit-main_subscription-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}
	}
	.lit-main-secondary-header {
		background-color: rgb(113, 168, 241);
		color: white;
		.lit-main-subHeaders.language {
			min-width: 85px;
			display: inline-block;

			.lit-secondary-headings {
				font-weight: bold;
			}
		}
		.lit-main-subHeaders.production_child {
			min-width: 87px;
			max-width: 87px;
			text-align: center;
			word-wrap: break-word;
			align-items: center;
			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}
		.lit-main-subHeaders.production_youth_screening {
			min-width: 87px;
			max-width: 87px;
			text-align: center;
			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}
		.lit-main-subHeaders.production_youth_followup {
			min-width: 87px;
			max-width: 87px;
			text-align: center;
			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}
		.lit-main-subHeaders.production_adult_screening {
			min-width: 87px;
			max-width: 87px;
			text-align: center;
			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}
		.lit-main-subHeaders.production_adult_followup {
			min-width: 87px;
			max-width: 87px;
			text-align: center;
			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}
		.lit-main-subHeaders.norming_child {
			min-width: 118px;
			max-width: 118px;
			text-align: center;
			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}
		.lit-main-subHeaders.norming_youth {
			min-width: 118px;
			max-width: 118px;
			text-align: center;
			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}
		.lit-main-subHeaders.norming_adult {
			min-width: 118px;
			max-width: 118px;
			text-align: center;
			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}
		.lit-main-subHeaders.basic {
			min-width: 86px;
			max-width: 86px;
			text-align: center;
			background-color: transparent;
			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}
		.lit-admin-subHeaders.basic {
			min-width: 86px;
			max-width: 86px;
			text-align: center;
			background-color: transparent;
			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}
		.lit-main-subHeaders.final {
			min-width: 80px;
			max-width: 80px;
			text-align: center;
			background-color: transparent;
			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 10px;
			}
		}
	}
	.lit-main-tertiary-header {
		color: black;
		.lit-main-subHeaders.language {
			color: white;
			background-color: rgb(113, 168, 241);
			.lit-secondary-headings {
				height: 10px;
				font-weight: bold;
				margin-top: 13px;
				margin-left: 15px;
			}
		}
		.lit-main-subHeaders.production_child {
			min-width: 87px;
			max-width: 87px;
			text-align: center;
			background-color: rgb(147, 185, 235);
			color: white;
			word-wrap: break-word;
			.lit-secondary-headings {
				font-weight: normal;
				font-size: 14px;
				height: 10px;
				margin-top: 5px;
				margin-left: 5px;
			}
		}
		.lit-main-subHeaders.production_youth_screening {
			min-width: 87px;
			max-width: 87px;
			text-align: center;
			background-color: rgb(147, 185, 235);
			color: white;
			.lit-secondary-headings {
				font-weight: normal;
				font-size: 14px;
				height: 10px;
				margin-top: 8px;
				margin-left: 5px;
			}
		}
		.lit-main-subHeaders.production_youth_followup {
			min-width: 87px;
			max-width: 87px;
			background-color: rgb(147, 185, 235);
			color: white;
			text-align: center;
			.lit-secondary-headings {
				font-weight: normal;
				font-size: 14px;
				height: 10px;
				margin-top: 8px;
				margin-left: 5px;
			}
		}
		.lit-main-subHeaders.production_adult_screening {
			min-width: 87px;
			max-width: 87px;
			text-align: center;
			background-color: rgb(147, 185, 235);
			color: white;
			.lit-secondary-headings {
				font-weight: normal;
				font-size: 14px;
				height: 10px;
				margin-top: 8px;
				margin-left: 5px;
			}
		}
		.lit-main-subHeaders.production_adult_followup {
			min-width: 87px;
			max-width: 87px;
			text-align: center;
			background-color: rgb(147, 185, 235);
			color: white;
			.lit-secondary-headings {
				font-weight: normal;
				font-size: 14px;
				height: 10px;
				margin-top: 8px;
				margin-left: 5px;
			}
		}
		.lit-main-subHeaders.norming_child {
			min-width: 118px;
			max-width: 118px;
			text-align: center;
			background-color: rgb(147, 185, 235);
			color: white;
			.lit-secondary-headings {
				font-weight: normal;
				font-size: 14px;
				height: 10px;
				margin-top: 8px;
				margin-left: 5px;
			}
		}
		.lit-main-subHeaders.norming_youth {
			min-width: 118px;
			max-width: 118px;
			text-align: center;
			background-color: rgb(147, 185, 235);
			color: white;
			.lit-secondary-headings {
				font-weight: normal;
				font-size: 14px;
				height: 10px;
				margin-top: 8px;
				margin-left: 5px;
			}
		}
		.lit-main-subHeaders.norming_adult {
			min-width: 118px;
			max-width: 118px;
			text-align: center;
			background-color: rgb(147, 185, 235);
			color: white;
			.lit-secondary-headings {
				font-weight: normal;
				font-size: 14px;
				height: 10px;
				margin-top: 8px;
				margin-left: 5px;
			}
		}
		.lit-main-subHeaders.basic {
			min-width: 86px;
			max-width: 86px;
			text-align: center;
			background-color: rgb(147, 185, 235);
			color: white;
			.lit-secondary-headings {
				font-weight: normal;
				font-size: 14px;
				height: 10px;
				margin-top: 8px;
				margin-left: 5px;
			}
		}
		.lit-admin-subHeaders.basic {
			min-width: 86px;
			max-width: 86px;
			text-align: center;
			background-color: rgb(147, 185, 235);
			color: white;
			.lit-secondary-headings {
				font-weight: normal;
			}
		}
		.lit-main-subHeaders.final {
			min-width: 105px;
			max-width: 120px;
			text-align: center;
			background-color: rgb(147, 185, 235);
			color: white;
			.lit-secondary-headings {
				font-weight: normal;
			}
		}
	}
}

.lit-table_data {
	font-size: 15px;
	font-weight: bold;
}
.previous-tests {
	margin-top: 20px;
	float: left;
	&__heading {
		animation: fade 0.4s;
	}
}
.lit_deleted-tests {
	margin-top: 20px;
	float: left;
	&__heading {
		animation: fade 0.4s;
	}
}
.detailed-subscription {
	margin-top: 20px;
	float: left;
	margin-left: 10px;
	&__heading {
		animation: fade 0.4s;
	}
}
.lit-input.inline .lit-input__label.wide {
	min-width: 120px;
	margin-left: 8px;
}
.tab-heading {
	margin-top: 40px;
}

.tickmark {
	font-weight: bold;
	color: green;
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
		sans-serif;
	-ms-transform: scaleX(-1) rotate(-35deg);
	-webkit-transform: scaleX(-1) rotate(-35deg);
	transform: scaleX(-1) rotate(-35deg);
	min-width: 65px;
}
.tickmark.norming {
	font-weight: bold;
	color: green;
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
		sans-serif;
	-ms-transform: scaleX(-1) rotate(-35deg);
	-webkit-transform: scaleX(-1) rotate(-35deg);
	transform: scaleX(-1) rotate(-35deg);
	min-width: 96px;
}
.crossmark {
	font-family: arial;
	font-weight: bold;
	color: red;
	min-width: 65px;
}
.crossmark.norming {
	font-family: arial;
	font-weight: bold;
	color: red;
	min-width: 96px;
}
.tickmark.basic {
	background-color: transparent;

	font-weight: bold;
	color: green;
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
		sans-serif;
	-ms-transform: scaleX(-1) rotate(-35deg);
	-webkit-transform: scaleX(-1) rotate(-35deg);
	transform: scaleX(-1) rotate(-35deg);
	min-width: 64px;
}
.tickmark-admin.basic {
	background-color: transparent;

	font-weight: bold;
	color: green;
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
		sans-serif;
	-ms-transform: scaleX(-1) rotate(-35deg);
	-webkit-transform: scaleX(-1) rotate(-35deg);
	transform: scaleX(-1) rotate(-35deg);
	min-width: 66px;
}
.crossmark.basic {
	background-color: transparent;
	font-family: arial;
	font-weight: bold;
	color: red;
	min-width: 64px; //66px
}
.crossmark-admin.basic {
	background-color: transparent;
	font-family: arial;
	font-weight: bold;
	color: red;
	min-width: 66px;
}
.tables {
	display: inline-block;
	float: left;
}

//for Edit subscription

.one_main-options {
	margin-top: 7px;
}

.two-main_options {
	margin-top: 5px;
}
.three-main_options {
	margin-top: 5px;
}
.lang_label {
	font-size: 20px;
	color: black;
}
.user_checkbox {
	margin-top: 10px;
	transform: scale(1.4);
}

.lit-modal__island-newLogin {
	display: flex;
	justify-content: center;
	align-items: center;
	//background-color: white;
	//border: solid thin rgb(240, 235, 235);

	border: none;
	background: none;

	//height: 60%;
	//width: 70%;
	//padding: 5%;
	//margin: 40px;
	//margin-left: 18%;
}
.lit-verifyLogin {
	max-width: 600px;
	min-width: 350px;
	border: solid rgb(240, 235, 235);
	background: white;
	padding: 10px;
	border-radius: 20px;
	overflow: hidden;
}

.lit-change-loginMethod {
	background-color: rgba(255, 255, 255, 0.438);
	padding: 1rem;
	border: solid 0.5em white;
	border-radius: 1rem;
	p {
		font-size: 1rem;
	}
}
