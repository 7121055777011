.lit-list {
	list-style: none;
	padding: 0;
	margin: $space 0;
	width: 100%;

	&__item {
		background-color: $white;
		border-bottom: 1px solid $lightGray;

		td {
			padding: 2px $space-xsm;
		}

		&:nth-child(even) {
			background-color: #eee;
		}

		&:last-of-type {
			box-shadow: $boxShadow1;
		}

		@include transitions {
			@include stagger(fade, 10);
		}

		&:first-of-type {
			border-radius: $borderRadius $borderRadius 0 0;
		}
		&:last-of-type {
			border-radius: 0 0 $borderRadius $borderRadius;
			border-bottom: none;
		}
	}

	&__col {
		border-right: 1px solid $offwhite;
		padding: $space $space-lg;
		text-align: center;
		min-width: 60px;

		&.small {
			min-width: 75px;
		}

		&:last-of-type {
			border-right: none;
		}
	}

	&__heading {
		margin: 0 0 $space-xsm;
	}

	&__text {
		line-height: $h3;
		margin: 0;

		&.large {
			font-size: $h3;
			font-weight: bold;
		}
	}
}
