.lit-menu {
	// padding-top: $space;

	ul {
		padding: 0;
		margin: 0;
		list-style: none;
	}

	&__item {
		padding: ($space * 0.75) $space;
		display: block;
		width: 100%;
		text-align: left;

		@include transitions {
			transition: background-color 0.12s;

			&:hover {
				background-color: rgba(255, 255, 255, 0.15);
			}
		}

		&:active {
			transition: none;
			background-color: rgba(255, 255, 255, 0.4);
		}

		&.selected {
			background-color: rgba(255, 255, 255, 1);
			cursor: default;
			box-shadow: $boxShadow1;
			color: $textColor;
		}
	}
}
