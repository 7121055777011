@import './vars/breakpoints';
@import './vars/mixins';
@import './vars/colors';
@import './vars/typography';
@import './vars/environment';

.lit-btn {
	display: inline-block;
	vertical-align: middle;
	padding: 0.8em 1.6em;
	font-size: 1em;
	font-size: $p;
	line-height: 1.4;
	cursor: pointer;
	border: 2px solid transparent;
	text-align: center;
	border-radius: $borderRadius;
	letter-spacing: 0.015em;
	user-select: none;
	font-family: $fontFamily;
	box-shadow: $boxShadow1;
	min-width: 0;

	@include noFocus;

	&--margin {
		margin: 0.5em;
	}

	&--fixed {
		width: 140px;
	}

	&--large {
		font-size: 1.1em;
		padding: 0.8em 2em;
	}

	&--huge {
		font-size: 2em;
		padding: 0.5em 2em;
		box-shadow: $boxShadow3;
	}

	&--small {
		padding: 0.5em 1.2em;
		font-size: $small;
	}

	&--tiny {
		padding: 0.5em 1.2em;
		font-size: $tiny;
	}

	&.right {
		float: right;
	}

	&.lit-ico {
		padding: 0;
		font-size: 0;
	}

	&--round {
		$roundButtonSize: 46px;
		box-sizing: content-box;
		padding: $space-xsm;
		width: $roundButtonSize;
		height: $roundButtonSize;
		line-height: $roundButtonSize;
		text-align: center;
		border-radius: 50%;

		> .lit-icon {
			width: $roundButtonSize;
			height: $roundButtonSize;
			line-height: $roundButtonSize;
			font-size: 25px;
			margin-right: 0;
		}

		> .lit-btn__text {
			position: absolute;
			color: $textColor;
			white-space: nowrap;
			// left: 120%;
			bottom: 80%;
			left: 50%;
			transform: translateX(-50%);
			text-align: center;
			height: $roundButtonSize;
			line-height: $roundButtonSize;
			font-size: $small;

			&.left,
			&.right {
				bottom: auto;
				transform: none;
			}

			&.right {
				left: 100%;
				right: auto;
				padding-left: $space-sm;
			}

			&.left {
				left: auto;
				right: 100%;
				padding-right: $space-sm;
			}
		}

		&.lit-btn--small {
			$smallRoundButtonSize: 20px;
			width: $smallRoundButtonSize;
			height: $smallRoundButtonSize;
			line-height: $smallRoundButtonSize;

			> .lit-icon {
				width: $smallRoundButtonSize;
				height: $smallRoundButtonSize;
				line-height: $smallRoundButtonSize;
				margin-top: -2px;
				font-size: 13px;
			}

			> .lit-btn__text {
				height: $smallRoundButtonSize;
				line-height: $smallRoundButtonSize;
				font-size: $tiny;

				&.right {
					padding-left: $space-xsm;
				}

				&.left {
					padding-right: $space-xsm;
				}
			}
		}

		@include transitions {
			> .lit-btn__text {
				transition: color 0.2s;
			}

			&:hover {
				> .lit-btn__text {
					color: lighten($linkColor, 0%);
				}
			}
		}

		@include mobileThin {
			$roundButtonSizeMobile: 40px;
			width: $roundButtonSizeMobile;
			height: $roundButtonSizeMobile;
			line-height: $roundButtonSizeMobile;

			> .lit-icon {
				width: $roundButtonSizeMobile;
				height: $roundButtonSizeMobile;
				line-height: $roundButtonSizeMobile;
				font-size: 24px;
			}

			> .lit-btn__text {
				height: $roundButtonSizeMobile;
				line-height: $roundButtonSizeMobile;
			}
		}
	}

	@include mobile {
		font-size: $p;
		padding: $space-sm $space;

		&--round {
			padding: $space-xsm;
		}
	}
}
