@include print {
	body {
		background: transparent;
	}

	* {
		box-shadow: none !important;
	}

	.print-hide {
		float: right;
		display: none;
	}

	.lit-header {
		display: none;
	}

	.lit-sidebar {
		display: none;
	}

	.lit-view {
		background: transparent;
		min-height: 0;
		box-shadow: none;
		margin: 0;
		padding: $space $space-xsm 0;

		&__content {
			background: transparent;
			min-height: 0;
			box-shadow: none;
			margin: 0;
			padding: $space $space-xsm 0;
		}
	}

	.explanation__text {
		.print-show {
			display: inline !important;
		}
	}
}
