@import '../../styles/common';
.lit-single-youthtest-title-desc {
	// godd color rgb(13, 148, 81)
	color: rgb(5, 10, 27);
	margin-top: 30px;
	.lit-page-title {
		margin-left: 3px;
	}
	.lit-page-lead {
		margin-left: 3px;
		padding-bottom: 4px;
	}
}
.lit-single-adulttest-title-desc {
	// godd color rgb(13, 148, 81)
	color: rgb(5, 10, 27);
	margin-top: 35px;
	.lit-page-title {
		margin-left: 3px;
	}
	.lit-page-lead {
		margin-left: 3px;
		padding-bottom: 4px;
	}
}
.lit-input-information {
	float: right;
	padding-left: 10px;
}
.newBorderDiv {
	border-left: solid rgba(49, 123, 156, 255);
	border-top: solid rgba(49, 123, 156, 255);
	border-width: 2px;
	padding-left: 3px;
	padding-bottom: 7px;
	border-bottom-left-radius: 10px;
}
.lit-tab-wrapper {
	display: flex;
	background-color: rgba(38, 126, 165, 255);
	padding-left: 6;
	height: 50px;
	margin-left: -2px;
	margin-bottom: 5px;
	margin-bottom: 10px;
	.tablink1 {
		display: flex;
		margin-left: -1px;
		position: relative;
		display: inline-block;
		background-color: transparent;
		color: white;
		height: 100%;
		width: 185.14;
		top: -1.5px;
		text-align: left;
		font-size: 20px;
		min-width: 178px;
		border-left: solid rgba(38, 126, 165, 255);
		border-top: solid rgba(38, 126, 165, 255);
	}
	.tablink1-active {
		margin-left: -1px;
		display: inline-block;
		background-color: rgba(247, 247, 247, 255);
		color: black;
		height: 100%;
		min-width: 178px;
		width: 185.14;
		border: solid rgba(38, 126, 165, 255);
		border-width: 1px 1px 0 0px;
		text-align: left;
		font-weight: bold;
		font-size: 20px;
	}

	.tablink2 {
		display: flex;
		margin-left: -1px;
		display: inline-block;
		background-color: transparent;
		color: white;
		height: 100%;
		width: 185.14;
		position: relative;
		min-width: 178px;
		top: -1.5px;
		text-align: left;
		font-size: 20px;
		border-left: solid rgba(38, 126, 165, 255);
		border-top: solid rgba(38, 126, 165, 255);
	}

	.tablink2-active {
		margin-left: -1px;
		display: inline-block;
		background-color: rgba(247, 247, 247, 255);
		color: black;
		height: 100%;
		min-width: 178px;
		width: 185.14;
		border: solid rgba(38, 126, 165, 255);
		border-width: 1px 1px 0 1px;
		text-align: left;
		font-weight: bold;
		font-size: 20px;
	}
	.tablink3 {
		margin-left: -1px;
		display: inline-block;
		background-color: transparent;
		color: white;
		height: 100%;
		min-width: 178px;
		width: 185.14;
		position: relative;
		top: -1.5px;
		text-align: left;
		padding: 10px;
		font-size: 20px;
		border-left: solid rgba(38, 126, 165, 255);
		border-top: solid rgba(38, 126, 165, 255);
	}
	.tablink3-active {
		margin-left: -1px;
		display: inline-block;
		background-color: rgba(247, 247, 247, 255);
		color: black;
		height: 100%;
		min-width: 178px;
		width: 185.14;
		border: solid rgba(38, 126, 165, 255);
		border-width: 1px 1px 0 1px;
		text-align: left;
		font-weight: bold;
		font-size: 20px;
	}

	.tablink1:hover {
		background-color: rgba(76, 144, 177, 255);
		border: solid rgba(38, 126, 165, 255);
		border-width: 1px 1px 0 1px;
		color: white;
	}
	.tablink2:hover {
		background-color: rgba(76, 144, 177, 255);
		border: solid rgba(38, 126, 165, 255);
		border-width: 1px 1px 0 1px;
		color: white;
	}
	.tablink3:hover {
		background-color: rgba(76, 144, 177, 255);
		border: solid rgba(38, 126, 165, 255);
		border-bottom: none;
		border-width: 1px 1px 0 1px;
		color: white;
	}

	.dormanttablink3 {
		margin-left: -1px;
		width: 814px;
		display: inline-block;
		background-color: rgba(38, 126, 165, 255);
		color: transparent;
		height: 100%;
		border: solid rgba(38, 126, 165, 255);
		border-width: 0 0 0 0;
		text-align: left;
		font-size: 22px;
	}
}

.lit-single-test {
	//copied css goes here
	nav {
		position: relative;
		display: flex;
	}
	nav a {
		position: relative;
		margin: 0 10px;
		font-size: 20px;
		color: rgb(2, 1, 1);
		font-family: 'Trebuchet MS', sans-serif;
		text-decoration: none;
	}
	nav #marker-default {
		position: absolute;
		left: 10px;
		height: 4px;
		width: 90px;
		background: rgb(248, 246, 246);
		bottom: -8px;
		transition: 0.5s;
		border-radius: 4px;
	}
	nav #marker-report {
		position: absolute;
		left: 121px;
		height: 4px;
		width: 74px;
		background: rgb(253, 252, 252);
		bottom: -8px;
		transition: 0.5s;
		border-radius: 4px;
	}
	nav #marker-information {
		position: absolute;
		left: 215px;
		height: 4px;
		width: 190px;
		background: rgb(250, 248, 248);
		bottom: -8px;
		transition: 0.5s;
		border-radius: 4px;
	}
	//copied css ends here
	.incomplete-notice {
		text-align: center;
		padding: 20px 20px;
		background-color: #ffbf3b;
		border-radius: 10px;
		margin-bottom: 10px;
	}

	.print-show {
		display: none;
	}
	.info {
		margin-top: 20px;
		background-color: $white;
		box-shadow: $boxShadow1;
		max-width: 600px;
		padding: 0 $space;

		&__label {
			display: inline-block;
			vertical-align: top;
			width: 100%;
			max-width: 240px;
			margin-right: $space-m;
		}

		&__group {
			margin-top: $space-lg;

			h3,
			h4 {
				font-weight: bold;
				padding: 0 $space;
				margin: 0;
			}
			.indent {
				padding: 0 $space;
			}
		}

		&__row {
			border-bottom: 1px solid $lightGray;
			padding: $space $space;
			margin: 0;
		}
	}

	.summary-chart {
		max-width: 600px;
		margin: 0 auto;
	}

	.sub-test {
		font-size: $p;
		margin: $space-m 0 $space-xlg;
		max-width: 1200px;

		&__link {
			color: $linkColor;
		}

		&__name {
			text-align: center;
			margin-bottom: -26px;

			&:after {
				content: '';
				display: block;
				max-width: 400px;
				height: 1px;
				background-color: $offwhite;
				margin: $space-xsm auto $space-m;
			}

			&.big {
				font-size: $h2;
			}
		}

		&__scores {
			background-color: $white;
			box-shadow: $boxShadow1;
			padding: $space $space-lg;
		}

		&__col {
			font-size: $p;
			width: 50%;
			display: inline-block;
			padding: 0 $space-lg;
			vertical-align: top;
			border-right: 1px solid $lightGray;

			&:last-of-type {
				border-right: none;
			}
		}

		&__heading {
			text-align: center;
			// font-weight: bold;

			&.big {
				font-weight: bold;
				font-size: $h3;
				line-height: $h3;
			}
		}

		&__value {
			&.big {
				font-size: $h2;
				font-weight: bold;
			}
		}
		&__assessmentText {
			word-wrap: break-word;
		}

		&.total {
			margin-top: $space-xlg;

			.sub-test__scores {
				padding: $space-lg;
				margin: 0 auto;
			}
			.sub-test__col {
				width: 50%;
			}
		}

		.write-up {
			padding-left: $space-lg;
		}

		&__answers {
			column-count: 3;

			&.col-4 {
				column-count: 4;
			}
			&.col-3 {
				column-count: 3;
			}
			&.col-2 {
				column-count: 2;
			}
			&.col-1 {
				column-count: 1;
			}

			&--show-not-answered {
				& .sub-test__answer--not-answered {
					display: inline-block;
				}
			}
			&__heading {
				margin-top: $space-sm;
				font-size: 20px;
				font-weight: bold;
			}

			@media screen and (max-width: 1050px) {
				column-count: 2;

				&.col-4 {
					column-count: 3;
				}
				& .col-3 {
					column-count: 2;
				}
				& .col-2 {
					column-count: 1;
				}
				& .col-1 {
					column-count: 1;
				}
			}
			@media screen and (max-width: 750px) {
				column-count: 1;
				&.col-4 {
					column-count: 2;
				}
				& .col-3 {
					column-count: 1;
				}
				& .col-2 {
					column-count: 1;
				}
				& .col-1 {
					column-count: 1;
				}
			}
		}
		&__answer {
			vertical-align: top;
			font-weight: normal;
			width: 100%;
			display: inline-block;
			box-sizing: border-box;
			color: $textColor;
			padding-right: $space;
			padding-bottom: $space-sm;

			& p {
				margin: 0;
			}

			&__li {
				list-style-position: inside;
				margin-bottom: $space-sm;
			}
			&__ol {
				padding: 0;
				margin: 0;
			}

			&--block {
				display: block;
			}
			&--compact {
				padding-bottom: $space-sm;
			}
			&--correct {
				color: $correctColor;
			}
			&--wrong {
				color: $wrongColor;
				font-weight: 700;
			}
			&--not-answered {
				display: none;
			}
		}
		&__not-answered {
			font-size: 16px;
		}
		& .hidden {
			display: none;
		}
		& .center {
			margin: 0 auto;
			text-align: center;
		}
	}
	.sub-test-Report {
		font-size: $p;
		margin: $space-m 0 40px;
		max-width: 1200px;

		&__link {
			color: $linkColor;
		}

		&__name {
			text-align: left;
			margin-bottom: -26px;
			margin-left: 20px;
			font-weight: bold;

			&:after {
				content: '';
				display: block;
				max-width: 400px;
				height: 1px;
				background-color: $offwhite;
				margin: $space-xsm auto $space-m;
			}

			&.big {
				font-size: $h2;
			}
		}

		&__scores {
			background-color: $white;
			box-shadow: $boxShadow1;
			padding: $space $space-lg;
		}

		&__col {
			font-size: $p;
			width: 50%;
			display: inline-block;
			padding: 0 $space-lg;
			vertical-align: top;
			border-right: 1px solid $lightGray;

			&:last-of-type {
				border-right: none;
			}
		}

		&__heading {
			text-align: left;
			// font-weight: bold;

			&.big {
				font-weight: bold;
				font-size: $h3;
				line-height: $h3;
			}
		}
		&__margin {
			width: 100%;
			background-color: gray;
			height: 1px;
		}

		&__value {
			font-size: $h3;

			&.rawscore {
				white-space: pre;
			}
			&.big {
				font-size: $h2;
				font-weight: bold;
			}
		}

		&.total {
			margin-top: $space-xlg;

			.sub-test-Report__scores {
				padding: $space-lg;
				margin: 0 auto;
			}
			.sub-test-Report__col {
				width: 50%;
			}
		}

		.write-up {
			padding-left: $space-lg;
		}

		&__answers {
			column-count: 3;

			&.col-4 {
				column-count: 4;
			}
			&.col-3 {
				column-count: 3;
			}
			&.col-2 {
				column-count: 2;
			}
			&.col-1 {
				column-count: 1;
			}

			&--show-not-answered {
				& .sub-test-Report__answer--not-answered {
					display: inline-block;
				}
			}
			&__heading {
				margin-top: $space-sm;
				font-size: 20px;
				font-weight: bold;
			}

			@media screen and (max-width: 1050px) {
				column-count: 2;

				&.col-4 {
					column-count: 3;
				}
				& .col-3 {
					column-count: 2;
				}
				& .col-2 {
					column-count: 1;
				}
				& .col-1 {
					column-count: 1;
				}
			}
			@media screen and (max-width: 750px) {
				column-count: 1;
				&.col-4 {
					column-count: 2;
				}
				& .col-3 {
					column-count: 1;
				}
				& .col-2 {
					column-count: 1;
				}
				& .col-1 {
					column-count: 1;
				}
			}
		}
		&__answer {
			vertical-align: top;
			font-weight: normal;
			width: 100%;
			display: inline-block;
			box-sizing: border-box;
			color: $textColor;
			padding-right: $space;
			padding-bottom: $space-sm;

			& p {
				margin: 0;
			}

			&__li {
				list-style-position: inside;
				margin-bottom: $space-sm;
			}
			&__ol {
				padding: 0;
				margin: 0;
			}

			&--block {
				display: block;
			}
			&--compact {
				padding-bottom: $space-sm;
			}
			&--correct {
				color: $correctColor;
			}
			&--wrong {
				color: $wrongColor;
				font-weight: 700;
			}
			&--not-answered {
				display: none;
			}
		}
		&__not-answered {
			font-size: 16px;
		}
		& .hidden {
			display: none;
		}
		& .center {
			margin: 0 auto;
			text-align: center;
		}
	}
	.lit-printBtn {
		display: flex;
	}
	.lit-btn2 {
		height: 50px;
		width: 150px;
		margin-left: auto;
		border-color: #3629b5;
		border-radius: 5px 5px;
		font-size: medium;
		margin-bottom: -50px;
	}
	.lit-btn2:hover {
		background-color: #1e147c;
	}
	@media print {
		overflow: hidden;
		.lit-histogram-Test_Report {
			.explanations {
				page-break-after: always;
			}
		}
		-webkit-print-color-adjust: exact !important;
		.lit-single-adulttest-title-desc {
			background-color: rgb(139, 189, 223);
		}
		.lit-single-youthtest-title-desc {
			background-color: rgb(177, 226, 241);
		}
		.info {
			.info__row {
				padding: 10px 20px;
			}
		}

		.newBorderDiv {
			border: none;
		}
		.sub-test-Report {
			display: block;
		}
		.lit-tab-wrapper {
			display: none;
		}
		.sub-test__scores {
			border: none;
		}
		.tablink1 {
			display: none;
		}
		.tablink2 {
			display: none;
		}
		.tablink3 {
			display: none;
		}
		.tablink1-active {
			display: none;
		}
	}
	.lit-z-chart {
		margin-top: $space-lg;
	}

	.explanations {
		margin: $space 0;
		text-align: center;
		max-width: 1200px;
		page-break-after: 'always';

		&__heading {
			margin: 0;
			display: inline-block;
			vertical-align: middle;
			margin-right: $space;
			font-size: $h4;
			// font-weight: bold;
		}
	}
	.explanation {
		display: inline-block;
		vertical-align: middle;
		margin-right: $space-m;

		&__figure,
		&__text {
			display: inline-block;
			vertical-align: middle;
		}

		&__figure {
			width: 16px;
			height: 16px;
			border-radius: 50%;
			background: $blueGradient;
			margin-right: $space-xsm;
			box-shadow: $boxShadow3;

			&.negative {
				background: $redGradient;
			}
			&.positive {
				background: $greenGradient;
			}

			// @media print {
			// 	& {
			// 		display: none;
			// 	}
			// }
		}

		&__image {
			margin-right: $space-xsm;
			vertical-align: middle;
		}

		&__text {
			font-size: $small;
		}
	}

	.sub-test-horizontal-chart {
		position: relative;

		.sub-test__value {
			margin: 0;
			position: static;
			text-align: left;
			margin-top: $space-sm;
			font-size: $p;

			&.average {
				margin-top: 0;
				font-weight: normal;
				color: $gray;
				font-size: $h4;
			}

			&.rating {
				margin-top: $space-lg;
				// font-size: $h2;
				font-weight: bold;
			}
		}
	}

	.sub-test__help-label {
		font-size: 10px;
		position: absolute;
		right: 50%;
		left: 0;
		padding-right: 34px;
		top: 10px;
		text-align: left;
		color: $gray;

		&.average {
			top: auto;
			bottom: 5px;
		}
	}

	.sub-test-horizontal-bar {
		padding: $space-xsm 0;
		position: relative;

		&__wrapper {
			background-color: $lightGray;
			height: 2px;
			overflow: hidden;
			position: relative;
		}

		&__fill {
			height: 100%;
			width: 100%;
			background: #49b3c0;
			background: $blueGradient;
			color: $blue;

			&.positive {
				background: $greenGradient;
				color: $positiveColor;
			}

			&.negative {
				background: $redGradient;
				color: $negativeColor;
			}
		}

		&__average {
			width: 2px;
			height: 100%;
			border: 1px solid rgba(0, 0, 0, 0.1);
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	@include print {
		.info {
			padding: 0;
		}
		.lit-histogram-Test_Report {
			-webkit-print-color-adjust: exact !important;
		}

		.sub-test {
			border: 1px solid $gray;
			border-radius: $borderRadius;
			padding: $space-sm 0;
			margin: $space-sm 0;
			page-break-inside: avoid;

			&__scores {
				padding: 0;
			}

			&.total {
				border: none;

				.sub-test {
					&__scores {
						padding: 0;
					}

					&__col {
						width: auto;
						display: block;
						border-right: none;
						padding-top: $space;
					}
				}

				.lit-z-chart {
					width: 576px;
					margin: $space-lg auto $space-xlg;
					display: block;
				}
			}
			&.print-summary {
				-webkit-print-color-adjust: exact !important;
			}
		}

		.sub-test-horizontal-chart {
			.sub-test__value {
				margin-top: $space-xsm;
			}

			.sub-test__value.average {
				color: $mediumGray;
			}
		}

		.sub-test-horizontal-bar {
			&__wrapper {
				border: 1px solid #000;
				height: 6px;
			}

			&__fill {
				-webkit-print-color-adjust: exact;
				border: none;
				border-top: 5px solid;
				border-bottom: 5px solid;
			}

			&__average {
				border: none;
				border-right: 1px solid $gray;
			}
		}

		.print-sub-tests {
			page-break-after: avoid;
		}
	}
}
.lit-indented {
	&__under {
		font-style: italic;

		color: rgb(223, 55, 13);
	}
	&__over {
		font-style: italic;

		color: rgb(59, 121, 83);
	}
	&__in {
		font-style: italic;
		color: rgb(32, 115, 240);
	}
}
