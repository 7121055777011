@import '../../styles/common';
/* 

*/
.lit-users {
	margin-top: -60px;
	padding-left: 3px;
	padding-bottom: 7px;
	min-width: 800px;
	overflow-x: auto;
	nav {
		position: relative;
		display: flex;
	}
	nav a {
		position: relative;
		margin: 0 10px;
		font-size: 20px;
		color: rgb(255, 255, 255);
		font-family: 'Trebuchet MS', sans-serif;
		text-decoration: none;
	}
	nav #marker-default {
		position: absolute;
		left: 11px;
		height: 4px;
		width: 70px;
		background: rgb(251, 252, 252);
		bottom: -8px;
		transition: 0.5s;
		border-radius: 4px;
	}
	nav #marker-activity {
		position: absolute;
		left: 99px;
		height: 4px;
		width: 80px;
		background: rgb(251, 252, 252);
		bottom: -8px;
		transition: 0.5s;
		border-radius: 4px;
	}
	nav #marker-summary {
		position: absolute;
		left: 199px;
		width: 115px;
		height: 4px;
		background: rgb(251, 252, 252);
		bottom: -8px;
		transition: 0.5s;
		border-radius: 4px;
	}
	nav #marker-invoices {
		position: absolute;
		left: 333px;
		width: 105px;
		height: 4px;
		background: rgb(251, 252, 252);
		bottom: -8px;
		transition: 0.5s;
		border-radius: 4px;
	}
	.register-form {
		max-width: 440px;
		margin: 0 auto;
	}
	.lit-Users_wrapper {
		margin-top: 30px;
		margin-bottom: -20px;
		display: flex;
		.lit-input_status {
			width: 300px;
		}
		.lit-input__field {
			margin-left: 5px;
			overflow: hidden;
			font-size: 16px;
			border: solid rgb(28, 138, 189);
			border-width: 0.1em;
		}
		.lit-input_serach-select-filters {
			margin-left: 5px;
			overflow: hidden;
			font-size: 16px;
			border: solid rgb(28, 138, 189);
			border-width: 0.1em;
		}

		.lit-input_searchUser {
			float: right;
			width: 300px;
			margin-left: 10px;
			margin-top: 20px;
		}
		.lit_email-btn {
			margin-top: 15px;
		}
		.lit_crate_group-btn {
			margin-top: 15px;
			margin-left: 5px;
		}
	}
	.lit_txtf_users {
		display: inline-block;
		font-size: 17px;
		width: 250px;
		height: 45px;
		margin-left: 5px;
		background-color: white;
		box-shadow: 3px 5px #888888;
	}
	.lit-input__dropDown {
		display: inline-block;
		margin-left: 5px;
		margin-right: 20px;
		border-radius: 2px;
		padding: 5px 10px;
		font-size: 14px;
		background: #fff;
	}
	.user-list {
		font-size: 14px;
		margin-top: $space-lg;
	}
	.lit-test-status__indicator {
		&.tiny_loggedIn {
			background-image: linear-gradient(45deg, #86be1d, #0ef052);
			height: 11px;
			width: 11px;
			margin-left: 2px;

			/* margin-top: 5px;
			float: right; */
		}
		&.tiny_loggedOut {
			background-image: linear-gradient(45deg, #e3ece3, #cfd6d3);
			height: 10px;
			width: 10px;
			margin-left: 2px;

			/* margin-top: 5px;
			
			float: right; */
		}
		&.tiny_blocked {
			background-image: linear-gradient(45deg, #f52c12, #ee211a);
			height: 10px;
			width: 10px;
			margin-left: 2px;

			/* margin-top: 5px;
			
			float: right; */
		}
	}
	.bg-primary3-red {
		background-color: red;
		color: white;
	}
	.bg-primary3 {
		background-color: rgb(48, 153, 57);
		color: white;
	}
	.bg-primary3:hover {
		background-color: rgb(20, 102, 27);
	}
}

.lit_check-input_label {
	margin-left: 5px;
}
.lit_btn_add-members {
	display: inline;
	margin-left: 15px;
}

.lit-input-newgroup {
	margin-top: 20px;
}
.lit_create_new-group {
	margin-top: 20px;
	margin-left: -15px;
}
.lit_crate_group-btn {
}

.lit_email-btn {
	margin-top: 10px;
	margin-left: 15px;
}
.lit_copy_email-textarea {
	height: 600px;
	width: 700px;
}
.lit_flag_creatingGroup {
	transform: scale(1.2);
}
.lit_flag_removefromgroup {
	margin-left: 20px;
	transform: scale(1.2);
}

.user_checkbox2 {
	display: inline-block;
	margin-left: 10px;
	margin-right: 10px;
	width: 16px;
	height: 16px;
	background: #fff;
	border-radius: 4px;
}
.lit-address_list {
	font-size: 20px;
}
.lit-main_textarea {
	margin-left: 250px;
	width: 400px;
	max-width: 100%;
	line-height: 4px;
	height: 400px;
	padding: 30px 40px 30px;
	border-radius: 3px;
	border: 1px solid #181715;
	font: 20px Tahoma, cursive;
	transition: box-shadow 0.5s ease;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	background: linear-gradient(#ececea, #f0ece3);
}

//copied css goes under
.lit-tab-wrapper {
	display: flex;
	background-color: rgba(38, 126, 165, 255);
	padding-left: 6;
	height: 50px;
	margin-left: -2px;
	.tablink1 {
		display: flex;
		margin-left: -1px;
		position: relative;
		display: inline-block;
		background-color: transparent;
		color: white;
		height: 100%;
		width: 185.14;
		top: -1.5px;
		text-align: left;
		font-size: 20px;
		min-width: 178px;
		border-left: solid rgba(38, 126, 165, 255);
		border-top: solid rgba(38, 126, 165, 255);
	}
	.tablink1-active {
		margin-left: -1px;
		display: inline-block;
		background-color: rgba(247, 247, 247, 255);
		color: black;
		height: 100%;
		min-width: 178px;
		width: 185.14;
		border: solid rgba(38, 126, 165, 255);
		border-width: 1px 1px 0 0px;
		text-align: left;
		font-weight: bold;
		font-size: 20px;
	}

	.tablink2 {
		display: flex;
		margin-left: -1px;
		display: inline-block;
		background-color: transparent;
		color: white;
		height: 100%;
		width: 185.14;
		position: relative;
		min-width: 178px;
		top: -1.5px;
		text-align: left;
		font-size: 20px;
		border-left: solid rgba(38, 126, 165, 255);
		border-top: solid rgba(38, 126, 165, 255);
	}

	.tablink2-active {
		margin-left: -1px;
		display: inline-block;
		background-color: rgba(247, 247, 247, 255);
		color: black;
		height: 100%;
		min-width: 178px;
		width: 185.14;
		border: solid rgba(38, 126, 165, 255);
		border-width: 1px 1px 0 1px;
		text-align: left;
		font-weight: bold;
		font-size: 20px;
	}
	.tablink3 {
		margin-left: -1px;
		display: inline-block;
		background-color: transparent;
		color: white;
		height: 100%;
		min-width: 178px;
		width: 185.14;
		position: relative;
		top: -1.5px;
		text-align: left;
		padding: 10px;
		font-size: 20px;
		border-left: solid rgba(38, 126, 165, 255);
		border-top: solid rgba(38, 126, 165, 255);
	}
	.tablink3-active {
		margin-left: -1px;
		display: inline-block;
		background-color: rgba(247, 247, 247, 255);
		color: black;
		height: 100%;
		min-width: 178px;
		width: 185.14;
		border: solid rgba(38, 126, 165, 255);
		border-width: 1px 1px 0 1px;
		text-align: left;
		font-weight: bold;
		font-size: 20px;
	}
	.tablink4 {
		margin-left: -1px;
		display: inline-block;
		background-color: transparent;
		color: white;
		height: 100%;
		min-width: 178px;
		width: 185.14;
		position: relative;
		top: -1.5px;
		text-align: left;
		padding: 10px;
		font-size: 20px;
		border-left: solid rgba(38, 126, 165, 255);
		border-top: solid rgba(38, 126, 165, 255);
	}
	.tablink4-active {
		margin-left: -1px;
		display: inline-block;
		background-color: rgba(247, 247, 247, 255);
		color: black;
		height: 100%;
		min-width: 178px;
		width: 185.14;
		border: solid rgba(38, 126, 165, 255);
		border-width: 1px 1px 0 1px;
		text-align: left;
		font-weight: bold;
		font-size: 20px;
	}
	.tablink1:hover {
		background-color: rgba(76, 144, 177, 255);
		border: solid rgba(38, 126, 165, 255);
		border-width: 1px 1px 0 1px;
		color: white;
	}
	.tablink2:hover {
		background-color: rgba(76, 144, 177, 255);
		border: solid rgba(38, 126, 165, 255);
		border-width: 1px 1px 0 1px;
		color: white;
	}
	.tablink3:hover {
		background-color: rgba(76, 144, 177, 255);
		border: solid rgba(38, 126, 165, 255);
		border-bottom: none;
		border-width: 1px 1px 0 1px;
		color: white;
	}
	.tablink4:hover {
		background-color: rgba(76, 144, 177, 255);
		border: solid rgba(38, 126, 165, 255);
		border-bottom: none;
		border-width: 1px 1px 0 1px;
		color: white;
	}

	.dormanttablink3 {
		margin-left: -1px;
		width: 814px;
		display: inline-block;
		background-color: rgba(38, 126, 165, 255);
		color: transparent;
		height: 100%;
		border: solid rgba(38, 126, 165, 255);
		border-width: 0 0 0 0;
		text-align: left;
		font-size: 22px;
	}
}
.lit-activity_grid {
	display: flex;
	cursor: pointer;
	justify-content: left;
	background-color: white;
	padding: 3px;
	max-height: 56.5px;
	border: solid rgb(5, 5, 5);
	border-width: 1.5px 1.5px 0.5px 1.5px;
	color: black;
	min-width: 1120px;
	.bg-primary6 {
		background-color: white;
		border: none;
		color: black;
	}
}
.lit-activity-table-container {
	background-color: white;
	padding: 10px;
	border-width: 0px 1px 1px 1px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.lit-table_wrapper {
	margin-top: -15px;
	.lit-input__field3 {
		width: 200px;
		margin-top: 20px;
		margin-left: 5px;
		overflow: hidden;
		font-size: 13px;
		border: solid rgb(28, 138, 189);
		border-width: 0.1em;

		background-color: #fff;
		height: 100%;
	}
}
.lit-activity_grid:hover {
	cursor: pointer;
	max-height: 56.5px;
	background-color: hsl(206, 51%, 46%);
	color: white;
	.bg-primary6 {
		background-color: hsl(206, 43%, 65%);
		color: white;
	}
}
.lit-activity_grid-active {
	display: flex;
	min-width: 1120px;
	justify-content: left;
	max-height: 56.5px;
	background-color: rgb(234, 238, 241);
	padding: 3px;

	border-bottom: none;
	border-width: 1.5px 1.5px 0.5px 1.5px;
	color: black;
	.bg-primary6 {
		background-color: rgb(218, 224, 231);
		color: black;
	}

	justify-content: center;
}
.myClass_table-cell {
	text-align: left;
	min-width: 180px;
	justify-content: left;
	align-items: left;
	margin-right: auto;
	word-wrap: break-word;
	max-width: 190px;
	padding: 8px;
}
.myClass_title_table-cell {
}

.lit_summary_table-cell {
	text-align: left;
	min-width: 120px;
	justify-content: left;
	align-items: left;
	margin-left: 30px;
	margin-right: auto;
	word-wrap: break-word;
	max-width: 190px;
	padding: 8px;
}
.myClass-subscription_table-cell {
	text-align: left;
	min-width: 220px;
	justify-content: left;
	align-items: left;
	margin-right: auto;
	word-wrap: break-word;
	max-width: 250px;
	padding: 8px;
}
.lit-myClass_individual-test {
}
.lit-subTable_identifiers {
	display: flex;
	align-items: center;

	.lit-input-testCount-heading {
		float: right;
		margin-top: 15px;
		margin-left: 15px;
	}
	.lit-input-testCount-info {
		float: right;
		margin-top: 15px;
		margin-left: 15px;
		font-size: large;
		font-weight: bold;
	}
}

.lit_input-filters-wrapper-activity {
	margin-top: 20px;
	display: flex;
	height: 40px;
	margin-bottom: -20px;
	.lit-input__field2 {
		width: 300px;
		margin-left: 5px;
		overflow: hidden;
		font-size: 16px;
		border: solid rgb(28, 138, 189);
		border-width: 0.1em;

		background-color: #fff;
		height: 100%;
	}
	.lit-input-userActivity_searchUser {
		height: 100%;
		float: right;
		width: 230px;
		margin-left: 10px;
		border: solid rgb(28, 138, 189);
		border-width: 0.1em;
	}
}

.lit-activity__field {
	display: flex;
	justify-content: center;
	align-items: center;
}
.lit-summary-table_view {
	display: flex;
}

.tickmark {
	font-weight: bold;
	color: green;
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
		sans-serif;
	-ms-transform: scaleX(-1) rotate(-35deg);
	-webkit-transform: scaleX(-1) rotate(-35deg);
	transform: scaleX(-1) rotate(-35deg);
}
.crossmark {
	font-family: arial;
	font-weight: bold;
	color: red;
}
//css for summary
.lit-summary_grid {
	display: flex;
	cursor: pointer;
	justify-content: left;
	background-color: white;
	padding: 3px;

	color: black;
	min-width: 1120px;
	.bg-primary6 {
		background-color: white;
		border: none;
		color: black;
	}
}
.myClass_table-new-subs-cell {
	margin-right: 190px;
	margin-left: -90px;
}
.lit-summary_title_table-cell {
	text-align: left;
	min-width: 180px;
	justify-content: left;
	align-items: left;
	margin-right: auto;
	word-wrap: break-word;
	max-width: 190px;
	padding: 8px;
}
.lit-date_filter {
	display: flex;
	justify-content: top;
	min-width: 300px;
	align-items: top;
	.lit-input_dateLabel {
		padding: 3px;
		justify-content: center;
		align-items: center;
	}
	padding: 3px;
}
.lit-conutry_picker {
	margin-left: 5px;
}
.lit-summary-table_wrapper {
	margin-left: 30px;
	margin-top: 20px;
}

.react-datepicker__month-container {
	width: 350px;
}
.react-datepicker__navigation--next {
	margin-right: 20px;
	width: 15px;
	height: 15px;
	/* border: none; */
	font-size: none;

	//
}
.react-datepicker__navigation--previous {
	width: 15px;
	height: 15px;
	/* border: none; */
	font-size: 1px;
	-webkit-text-emphasis: none;
	text-emphasis: none;
}
.react-datepicker__year-dropdown-container {
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-datepicker-wrapper,
.customDatePickerWidth
	> div
	> div.react-datepicker__input-container
	.customDatePickerWidth
	> div
	> div.react-datepicker__input-container
	input {
	width: auto;
	margin-left: 3px;
}
