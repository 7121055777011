@import '../../../styles/common';
.lit_Invoices_table-wrapper {
	margin-top: 10px;
}

.lit-Invoices-header_grid {
	display: flex;
	width: 1120px;
	height: 50px;
	background-color: #0da9e9;
	border: solid #0da9e9;
	border-width: 2px 2px 0px 2px;
	color: white;
	font-weight: bold;
}
.lit_input-filters-wrapper-invoices {
	display: flex;
	height: 40px;
	margin-top: 20px;
	.lit-input__field2 {
		width: 300px;
		margin-left: 5px;
		overflow: hidden;
		font-size: 16px;
		border: solid rgb(28, 138, 189);
		border-width: 0.1em;

		background-color: #fff;
		height: 100%;
	}
	.lit-input-userActivity_searchUser {
		height: 100%;
		float: right;
		width: 230px;
		margin-left: 10px;
		border: solid rgb(28, 138, 189);
		border-width: 0.1em;
	}
}
.lit-myClass-header-wrapper {
	display: flex;

	float: left;
}
.lit-invoices_grid {
	display: flex;
	cursor: pointer;
	justify-content: left;
	background-color: white;
	padding: 3px;
	max-height: 56.5px;
	border: solid rgb(5, 5, 5);
	border-width: 1.5px 1.5px 0.5px 1.5px;
	color: black;
	min-width: 1120px;
	.bg-primary6 {
		background-color: white;
		border: none;
		color: black;
	}
	.lit-summary-custom_btn-cell {
		max-height: 56.5px;
		background-color: transparent;
		.bg-primary7 {
			margin-top: 5px;
			background-color: #0da9e9;
			color: white;
		}
	}
}
.lit-tableRows-scroll {
	height: 695px;
	overflow-y: auto;
	overflow-x: hidden;
	min-width: 1120px;
	scrollbar-width: none;
	-ms-overflow-style: none;
}
.lit-tableRows-scroll::-webkit-scrollbar {
	width: 0;
	height: 0;
}
.lit-invoices_grid:hover {
	cursor: pointer;
	max-height: 56.5px;
	background-color: hsl(206, 51%, 46%);
	color: white;
	.bg-primary6 {
		background-color: hsl(206, 43%, 65%);
		color: white;
	}
	.lit-summary-custom_btn-cell {
		background-color: hsl(206, 51%, 46%);

		.bg-primary7 {
			background-color: hsl(206, 43%, 65%);
			color: white;
		}
	}
}
.lit-invoices_grid-active {
	cursor: pointer;
	display: flex;
	min-width: 1120px;
	justify-content: left;
	max-height: 56.5px;
	background-color: #2d5e38de;
	padding: 3px;
	border: solid black;
	border-bottom: none;
	border-width: 1.5px 1.5px 0.5px 1.5px;
	color: white;
	.bg-primary6 {
		background-color: rgb(55, 132, 233);
		color: white;
	}
	.lit-summary-custom_btn-cell {
		max-height: 56.5px;
		float: left;
		background-color: #3b7c49de;
		.bg-primary7 {
			background-color: rgb(233, 237, 243);
			color: black;
		}
	}

	justify-content: center;
}

.myClass_table-header {
	/* text-align: left;
	justify-content: left;
	align-items: left;

    min-width: 180px; */
	text-align: left;
	min-width: 180px;
	justify-content: left;
	align-items: left;
	word-wrap: break-word;
	max-width: 190px;
	padding: 8px;
	color: white;
}
.myClass_table-email-header {
	text-align: left;
	min-width: 240px;
	justify-content: left;
	align-items: left;
	word-wrap: break-word;
	max-width: 190px;
	color: white;
	padding: 8px;
}
.lit-summary_adminName-table-header {
	/* text-align: left;
	justify-content: left;
	align-items: left;

    min-width: 180px; */
	text-align: left;
	min-width: 240px;
	justify-content: left;
	align-items: left;
	margin-right: 30px;
	word-wrap: break-word;
	max-width: 240px;
	padding: 8px;
}
.lit-summary_testCount-table-header {
	text-align: left;
	min-width: 110px;
	justify-content: left;
	align-items: left;
	margin-right: 30px;
	word-wrap: break-word;
	max-width: 110px;
	padding: 8px;
}
.lit-summary_sinceLastInvoiced-table-header {
	text-align: left;
	min-width: 230px;
	justify-content: left;
	align-items: left;
	margin-right: 30px;
	word-wrap: break-word;
	max-width: 230px;
	padding: 8px;
}
.lit-summary_EmptyButton-table-header {
	text-align: left;
	min-width: 140px;
	justify-content: left;
	align-items: left;
	margin-right: 30px;
	word-wrap: break-word;
	max-width: 140px;
	padding: 8px;
}
.lit-summary_LastInvoiced-table-header {
	text-align: left;
	min-width: 230px;
	justify-content: left;
	align-items: left;
	margin-right: 30px;
	word-wrap: break-word;
	max-width: 230px;
	padding: 8px;
}
.lit-summary_Action-table-header {
	text-align: left;
	min-width: 230px;
	justify-content: left;
	align-items: left;
	margin-right: 30px;
	word-wrap: break-word;
	max-width: 230px;
	padding: 8px;
}
.myClass_table-organization-cell {
	text-align: left;
	min-width: 270px;
	justify-content: left;
	align-items: left;
	margin-right: auto;
	word-wrap: break-word;
	max-width: 190px;
	padding: 2px;
}

.myClass_averagescore_table-header {
	text-align: left;
	min-width: 200px;
	justify-content: left;
	align-items: left;
	margin-right: 30px;
	word-wrap: break-word;
	max-width: 210px;
	padding: 8px;
}
.lit-summary_table-cell_lastInvoice {
	/* 
	border: solid rgb(71, 189, 243);
	display: flex; */

	text-align: left;
	min-width: 230px;
	justify-content: left;
	align-items: left;
	margin-right: 30px;
	word-wrap: break-word;
	max-width: 230px;
	padding: 8px;
}
.lit-summary_table-cell-testCount {
	/* 
	border: solid rgb(71, 189, 243);
	display: flex; */

	text-align: left;
	min-width: 110px;
	justify-content: left;
	align-items: left;
	margin-right: 30px;
	word-wrap: break-word;
	max-width: 110px;
	padding: 8px;
}
.lit-summary_title_table-cell {
	text-align: left;
	min-width: 240px;
	justify-content: left;
	align-items: left;
	margin-right: 30px;
	word-wrap: break-word;
	max-width: 240px;
	padding: 8px;
}
.lit-summary_perviousInvoice_table-cell {
	text-align: left;
	min-width: 230px;
	justify-content: left;
	align-items: left;
	margin-right: 30px;
	word-wrap: break-word;
	max-width: 230px;
	padding: 8px;
}
.lit-summary_EmptyButton-table-cell {
	text-align: left;
	min-width: 140px;
	justify-content: left;
	align-items: left;
	margin-right: 30px;
	word-wrap: break-word;
	max-width: 140px;
	padding: 8px;
}
.lit-summary_btn-cell {
	float: left;
	max-height: 56.5px;
}
/* .lit-summary-custom_btn-cell {
	max-height: 56.5px;
	float: left;
	.bg-primary7 {
		background-color: rgb(55, 132, 233);
		color: white;
	}
} */
.lit-testActivity_selectDate {
	margin-left: 10px;
}
.react-datepicker-wrapper {
	border: solid rgb(28, 138, 189);
	border-width: 0.1em;
}
