.react-datepicker__input-container,
.react-datepicker__tether-element {
	input {
		@extend .lit-input__field;
	}

	.react-datepicker {
		border: none;
		box-shadow: $boxShadow2;

		@include transitions {
			animation: fade 0.6s;
		}
	}

	.react-datepicker__day--selected,
	.react-datepicker__day--in-selecting-range,
	.react-datepicker__day--in-range,
	.react-datepicker__day--keyboard-selected {
		background-color: $primary;

		&:hover {
			background-color: darken($primary, 10%);
		}
	}

	.react-datepicker__header {
		background-color: $primary;
		color: $white;
		border-bottom: none;
	}

	.react-datepicker__current-month {
		color: $white;
	}

	.react-datepicker__navigation--previous {
		border-right-color: $white;
	}

	.react-datepicker__navigation--next {
		border-left-color: $white;
	}

	.react-datepicker__tether-element-attached-bottom
		.react-datepicker__triangle::before,
	.react-datepicker__year-read-view--down-arrow::before,
	.react-datepicker__month-read-view--down-arrow::before {
		border-top-color: transparent;
		box-shadow: $boxShadow1;
	}

	.react-datepicker__tether-element-attached-top
		.react-datepicker__triangle::before {
		border-bottom-color: transparent;
	}
}
