@import '../../../styles/common';
.lit-tests-view__header {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;

	@media screen and (max-width: 1200px) {
		& {
			flex-direction: column;
		}
	}
}

.lit-btn_grid {
	margin-top: 18px;
	display: flex;
}
.audio {
	display: inline-block;
	max-width: 40px;
}
.audio audio {
	outline: none;
}

.lit-myClass-header_grid {
	min-width: 1019px;

	display: flex;
	justify-content: center;
	background-color: #0e688f;
	border-width: 2px 2px 0px 2px;
	color: black;
	justify-content: left;
	align-items: left;
}

.lit-myClass_grid {
	display: flex;
	min-width: 1019px;
	justify-content: left;
	background-color: rgb(255, 255, 255);
	padding: 3px;
	margin-bottom: 1px;
	border-width: 0.5px 0.5px 0.5px 0.5px;
	color: black;

	justify-content: center;
}

.lit-tab {
	&__expanded {
		opacity: 0;
		animation: registerExpand 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.05);
		animation-fill-mode: forwards;
		animation-delay: 0.4s;
		transform-origin: top;
	}
}
.lit-myClass_grid-active {
	display: flex;
	cursor: pointer;
	justify-content: left;
	background-color: hsl(200, 18%, 97%);
	padding: 3px;
	margin-top: 2px;
	color: black;
	border-bottom: thin 0.4px;
	min-width: 1019px;
	.bg-primary6 {
		color: black;
	}
}
.lit-input_addUser {
	margin-top: 10px;
}
.lit-input_searchUser {
	margin-top: 10px;
}

.lit-myClass-header-wrapper {
	display: flex;
	width: 100%;
	float: left;
}
.myClass_table-title-header {
	text-align: left;
	min-width: 180px;
	justify-content: left;
	align-items: left;
	margin-right: auto;
	word-wrap: break-word;
	max-width: 190px;
	padding: 8px;
	margin-top: 6px;
	color: white;
}

.myClass_table-header {
	text-align: left;
	min-width: 180px;
	justify-content: left;
	align-items: left;
	margin-right: auto;
	word-wrap: break-word;
	max-width: 190px;
	padding: 8px;
}
.myClass_table_title-header {
	text-align: left;
	min-width: 180px;
	justify-content: left;
	align-items: left;
	margin-right: auto;
	word-wrap: break-word;
	max-width: 190px;
	padding: 8px;
	color: whitesmoke;
}
.myClass_table_organization-header {
	text-align: left;
	min-width: 180px;
	justify-content: left;
	align-items: left;
	margin-right: auto;
	word-wrap: break-word;
	max-width: 190px;
	padding: 8px;
}
.myClass_table_email-header {
	text-align: left;
	min-width: 180px;
	justify-content: left;
	align-items: left;
	margin-right: auto;
	word-wrap: break-word;
	max-width: 190px;
	padding: 8px;
}

.myClass_averagescore_table-header {
	text-align: left;
	min-width: 200px;
	justify-content: left;
	align-items: left;
	margin-right: auto;
	word-wrap: break-word;
	max-width: 210px;
	padding: 8px;
	color: white;
}
.myClass_strength_table-cell {
	text-align: left;
	min-width: 180px;
	justify-content: left;
	align-items: left;
	margin-right: auto;
	word-wrap: break-word;
	max-width: 190px;
	padding: 8px;
	margin-top: 6px;
}
.myClass_table-cell {
	text-align: left;
	min-width: 180px;
	justify-content: left;
	align-items: left;
	margin-right: auto;
	word-wrap: break-word;
	max-width: 190px;
	padding: 8px;
}
.myClass_name_table-cell {
	text-align: left;
	min-width: 230px;
	justify-content: left;
	align-items: left;
	margin-right: auto;
	word-wrap: break-word;
	max-width: 220px;
	padding: 8px;
	margin-top: 6px;
}
.myClass_title_table-cell {
	text-align: left;
	min-width: 230px;
	justify-content: left;
	align-items: left;
	margin-right: auto;
	word-wrap: break-word;
	max-width: 220px;
	padding: 8px;
}
.myClass_meanscore_table-cell {
	margin-top: 15px;
	margin-right: 35px;
}
.lit-average-zs {
	display: flex;
	padding: 4px;
	color: rgb(5, 61, 124);
	align-items: center;
}
.lit-average-zs:hover {
}
.myClass_averagescore_table-cell {
	text-align: left;
	min-width: 105px;
	justify-content: left;
	align-items: left;
	margin-right: auto;
	word-wrap: break-word;
	max-width: 210px;
	padding: 8px;
}

.lit-tableRows-myClass-scroll {
	max-height: 575px;
	overflow-y: auto;
	overflow-x: hidden;
	min-width: 1120px;
	scrollbar-width: none;
	-ms-overflow-style: none;
}
.lit-tableRows-myClass-scroll::-webkit-scrollbar {
	width: 0;
	height: 0;
}

.lit_myClass_table-wrapper {
	margin-top: 10px;
	min-width: 1119px;
	padding: 5px;
}
.myClass_checkBox_header-cell {
	display: flex;
	padding: 3px;
	justify-content: left;
	align-items: left;
}
.user_checkbox1 {
	display: inline-block;
	margin-left: 8px;
	margin-right: 20px;
	width: 1.25em;
	height: 1.25em;
	background: #fff;
	border-radius: 4px;
}
.user_checkbox2 {
	margin-left: 4px;
}
.lit-myClasses_checkbox {
	display: inline-block;
	margin-right: 20px;
	width: 1.25em;
	height: 1.25em;
	background: #fff;
	border-radius: 4px;
}

.myClass_checkBox-cell {
	display: flex;
	padding: 3px;
	justify-content: left;
	align-items: left;
	margin-top: 8px;
}
.bg-primary6 {
	font-size: large;
	font-weight: bold;
	color: black;
	background-color: transparent;
}

.lit-myClass_individual-test {
	display: flex;
	position: relative;

	border: solid rgb(142, 177, 211);
	border-width: 0px 1px 1px 1px;
	margin-bottom: 5px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	background-color: white;
	min-height: 60px;
	padding-left: 5px;
	padding-top: -5px;
	margin-top: -3x;
	min-width: 1115px;
	height: 100%;
}
.lit-myClass_grid:hover {
	cursor: pointer;
	background-color: hsl(210, 4%, 81%);
	color: rgb(22, 94, 189);

	.bg-primary6 {
		color: rgb(22, 94, 189);
	}
}
.lit-btn-delete-class {
	display: flex;
	background-color: hsl(347, 71%, 38%);
	color: white;
	color: white;
	width: 150px;
	height: 50px;
	justify-content: center;
	align-items: center;
}
.className-title {
	text-decoration: underline;
	cursor: pointer;
}
.total-candidates {
	text-decoration: underline;
	cursor: pointer;
}
.lit-input-marginal {
	padding: 20px;
}
