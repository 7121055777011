@import '../../styles/common';
.user-stage-indicator {
	.lit-test-status__indicator {
		cursor: help;

		&.withText {
			cursor: default;
		}

		&.small {
			height: 15px;
			width: 15px;
		}
	}

	.user-stage-info-text {
		margin-left: $space-xsm;
	}
}

.needs-approval {
	background-color: red;
}
.awaiting {
	background-color: $purple;
}
.trial {
	background-color: $blue;
}
.basic {
	background-color: $pink;
}
.full {
	background-color: $green;
}
.normering {
	background-color: $green;
}
.inactive {
	background-color: $gray;
}
