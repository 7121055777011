.lit-flash {
	margin: 0 0 $space;
	padding: $space $space-lg;
	background-color: $white;
	box-shadow: $boxShadow1;

	@include preventChildMargin;

	&.error {
		background-color: $negativeColor;
	}
}
