.lit-filters-row {
	display: inline-flex;

	.lit-filters {
		display: flex;
		margin-top: $space-m;
		color: $textColor;
	}

	.lit-filter {
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
	}
}

.lit-filters {
	margin: $space-lgp 0 $space-sm;
	box-shadow: $boxShadow1;
	background-color: $white;
	display: inline-block;
	border-radius: $borderRadius;
	position: relative;

	+ .lit-filters {
		margin-left: $space;
	}

	&__heading {
		font-weight: bold;
		position: absolute;
		bottom: 110%;
		margin: 0;
		left: $space;
		left: 0;
		font-size: $small;
	}
}

.lit-filter {
	display: inline-block;
	vertical-align: top;

	&__field {
		display: none;
	}

	&__label {
		padding: $space-sm $space;
		display: block;
		font-size: $small;
		cursor: pointer;

		border-right: $offwhite 1px solid;

		@include transitions {
			transition: background-color 0.2s, box-shadow 0.1s;

			&:hover {
				background-color: lighten($primary, 30%);
			}
		}

		&:active {
			transition: none;
			background-color: lighten($primary, 20%);
		}
	}
	&:first-of-type {
		.lit-filter {
			&__label {
				border-radius: $borderRadius 0 0 $borderRadius;
			}
		}
	}

	&:last-of-type {
		.lit-filter {
			&__label {
				border-radius: 0 $borderRadius $borderRadius 0;
				border-right: 0;
			}
		}
	}

	.lit-filter__field:checked + .lit-filter__label {
		background-color: $primary;
		color: $white;
		box-shadow: $boxShadow4;

		.lit-test-status__label {
			color: inherit;
		}
	}

	.lit-test-status__indicator.all + .lit-test-status__label {
		margin-left: 0;
	}

	.lit-test-status {
		margin: 0;
	}
}

.lit-range {
	align-items: center;

	&__label {
		padding: 0 $space 0 0;
		height: 100%;
		display: inline-block;
		vertical-align: middle;

		.react-datepicker__input-container,
		.react-datepicker__tether-target {
			> input {
				@extend .lit-range__input;
			}
		}
	}

	&__from {
		display: inline-block;
		vertical-align: middle;
		margin: 0;
		margin-left: $space;
		font-size: $small;
	}

	&__input {
		box-shadow: $boxShadow4;
		background: $offwhite;
		border: 1px solid $lightGray;
		border-radius: $borderRadius;
		height: 100%;
		padding: $space-xsm $space-xsm;
		box-sizing: content-box;
		margin-left: $space-xsm;
		line-height: 15px;
		height: 15px;
		max-width: 100px;
	}

	&__number {
		max-width: 45px;
		text-align: center;
	}

	.lit-filter {
		display: flex;
		flex-direction: row;

		&__input {
			height: 100%;
		}
	}
}

.lit-clear-filter {
	margin-left: 10px;
}
