.lit-ico {
    width: 45px;
    height: 45px;
    display: inline-block;
    vertical-align: middle;
    background-position: center;
    background-size: contain;

    &--small {
        width: 35px;
        height: 35px;
    }

    &--large {
        width: 80px;
        height: 80px;
    }

    &--round {
        font-size: 0;
        border-radius: 50%;
    }

    &-back-arrow, &.back-arrow {
        background-image: url('../assets/icons/back-arrow.svg');

        &--primary {
            background-image: url('../assets/icons/back-arrow--primary.svg');
        }
    }
    &-right-arrow, &.right-arrow {
        background-image: url('../assets/icons/right-arrow.svg');

        &--primary {
            background-image: url('../assets/icons/right-arrow--primary.svg');
        }
    }
    &-eye, &.eye {
        background-image: url('../assets/icons/eye.svg');

        &--primary {
            background-image: url('../assets/icons/eye--primary.svg');
        }
    }
    &-edit, &.edit {
        background-image: url('../assets/icons/edit.svg');

        &--primary {
            background-image: url('../assets/icons/edit--primary.svg');
        }
    }
    &-image, &.image {
        background-image: url('../assets/icons/image.svg');

        &--primary {
            background-image: url('../assets/icons/image--primary.svg');
        }
    }
    &-trash, &.trash {
        background-image: url('../assets/icons/trash.svg');

        &--primary {
            background-image: url('../assets/icons/trash--primary.svg');
        }
    }
    &-close, &.close {
        background-image: url('../assets/icons/close.svg');

        &--primary {
            background-image: url('../assets/icons/close--primary.svg');
        }
    }
    &-add, &.add {
        background-image: url('../assets/icons/add.svg');

        &--primary {
            background-image: url('../assets/icons/add--primary.svg');
        }
        &--gray {
            background-image: url('../assets/icons/add--gray.svg');
        }
    }
    &-email, &.email {
        background-image: url('../assets/icons/email.svg');

        &--primary {
            background-image: url('../assets/icons/email--primary.svg');
        }
    }
    &-paper-plane, &.paper-plane {
        background-image: url('../assets/icons/paper-plane.svg');

        &--primary {
            background-image: url('../assets/icons/paper-plane--primary.svg');
        }
    }
    &-export, &.export {
        background-image: url('../assets/icons/export.svg');
    }
}
