.lit-z-chart {
	margin-top: $space;
	position: relative;
	font-size: 0;

	.lit-z-chart__value {
		position: absolute;
		bottom: 70%;
		right: 75%;
		z-index: $zPromote;
		text-align: left;
		margin: 0;
		text-shadow: 0px 0px 2px #fff;
		font-size: $h3;

		&.big {
			font-size: $h2;
			font-weight: bold;
		}
	}

	&__plain {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		opacity: 1;
	}

	&.big {
		.lit-z-chart__figure {
			opacity: 1;
		}
	}

	.lit-z-chart-bar {
		&__fill {
			transform: translateX(-50%);
		}
	}

	.lit-z-chart-bar {
		margin: 0 auto;
		width: 2px;
		top: 0;
		left: 0;
		position: absolute;
		bottom: 5.6%;
		margin-top: -$space;

		&__fill {
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: #49b3c0;
			background: $blueGradient;
			border-radius: 10px 10px 0 0;

			&.positive {
				background: $greenGradient;
			}

			&.negative {
				background: $redGradient;
			}
		}

		@include print {
			width: 5px;
			margin-bottom: -1px;

			&__fill {
				-webkit-print-color-adjust: exact;
				border: 1px solid #000;
				// background: #000 !important;
			}
		}
	}
}
