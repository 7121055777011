.lit-event-log {
	td {
		border-bottom: solid 1px #ccc;
		padding: 4px;
	}
}
.inline-btn {
	display: inline-block;
	margin-right: 5px;
}
