@import '../../styles/common';
.lit-tests-view {
	//margin-left: -55px;
	margin-top: -60px;
	padding-left: 0px;
	padding-bottom: 7px;
	nav {
		position: relative;
		display: flex;
	}
	nav a {
		position: relative;
		margin: 0 10px;
		font-size: 20px;
		color: rgb(255, 255, 255);
		font-family: 'Trebuchet MS', sans-serif;
		text-decoration: none;
	}
	nav #marker-default {
		position: absolute;
		left: 11px;
		height: 4px;
		width: 100px;
		background: rgb(251, 252, 252);
		bottom: -8px;
		transition: 0.5s;
		border-radius: 4px;
	}
	nav #marker-mytests {
		position: absolute;
		left: 240px;
		height: 34px;
		width: 18%;
		background: rgb(251, 252, 252);
		bottom: -8px;
		transition: 0.5s;
		border-radius: 4px;
	}
	nav #marker-mycandidates {
		position: absolute;
		left: 135px;
		width: 144px;
		height: 4px;
		background: rgb(251, 252, 252);
		bottom: -8px;
		transition: 0.5s;
		border-radius: 4px;
	}
	nav #marker-myclasses {
		position: absolute;
		left: 297px;
		width: 120px;
		height: 4px;
		background: rgb(251, 252, 252);
		bottom: -8px;
		transition: 0.5s;
		border-radius: 4px;
	}
}
.lit-myTests-scroller {
	margin-top: 15px;
	max-height: 93vh;
	overflow-y: auto;
	overflow-x: hidden;
	min-height: 90vh;
	min-width: 70vw;
	scrollbar-width: none;
	-ms-overflow-style: none;
}
.lit-myTests-scroller::-webkit-scrollbar {
	width: 0;
	height: 0;
}

.lit-tests-view__header {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;

	@media screen and (max-width: 1200px) {
		& {
			flex-direction: column;
		}
	}
}

.lit-file-upload {
	width: 80%;
	margin: auto;
}
.styled-table {
	border-collapse: collapse;
	margin: 25px 0;
	font-size: 0.9em;
	font-family: sans-serif;
	min-width: 400px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.styled-table thead tr {
	background-color: #009879;
	color: #ffffff;
	text-align: left;
}
.styled-table th,
.styled-table td {
	padding: 12px 15px;
	color: #ffffff;
}
.styled-table tbody tr {
	border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
	background-color: #649cb3;
	color: #080808;
}

.styled-table tbody tr:last-of-type {
	border-bottom: 2px solid #009879;
}
.styled-table tbody tr.active-row {
	font-weight: bold;
	color: #bfc8c7;
}
input[type='file']::file-selector-button {
	background-color: rgb(167, 213, 252);
	color: #000;
	border: 0px;
	border: 1px solid #e5e5e5;
	border-radius: 10px;
	padding: 10px 15px;
	margin-right: 20px;
	transition: 0.5s;
	box-shadow: 0 0 5px 3px rgb(46, 46, 46);
}

input[type='file']::file-selector-button:hover {
	background-color: #eee;
	border: 0px;
	border-right: 1px solid #e5e5e5;
}
.custom-file-upload {
	display: inline-block;
	padding: 16px 12px;
	cursor: pointer;
}
.lit-candidate-info {
	color: black;

	display: block;
	/* border: solid 1px rgb(226, 138, 37); */
	width: 95%;
	margin: auto;
	box-shadow: 0 0 5px 3px rgb(46, 46, 46);
	border: rgba(50, 128, 151, 0.61) solid 1px;
	border-radius: 33px;

	.lit-candidate-container {
		/* border: solid 1px rgb(50, 53, 205); */
		width: 100%;
		border: rgba(50, 128, 151, 0.61) solid 1px;
		background: linear-gradient(to bottom left, #3b6596, #082b4d);
		border-radius: 33px;
		flex-direction: row;
		box-shadow: 0 0 5px 3px rgb(46, 46, 46);

		margin: auto;
	}

	.lit-info__main-container {
		padding: 10px;
		width: 70%;
		margin-top: -10px;
		margin-left: auto;
		margin-right: auto;
		align-items: center;
		&__wrapper {
			width: 80%;
			margin: auto;
			align-items: center;
		}
		/* 	flex-wrap: wrap; */

		.lit-info__field {
			display: flex;
			flex-wrap: wrap;

			padding: 0.5em;
			font-size: large;
		}

		.lit-info__field-second {
			display: flex;
			flex-wrap: wrap;

			padding: 0.5em;
			font-size: large;
		}
		.lit-info__field-third {
			margin-top: -14px;
			display: inline-block;
			/* border: solid 1px rgb(197, 206, 197); */
			padding: 0.2em;
			font-size: large;
		}
		.lit-info__field-fourth {
			border: solid 1px rgb(169, 182, 169);
			color: white;
			font-size: large;

			border-radius: 10px;
			margin-right: 5px;
			padding: 5px;
		}
		.lit-info__field-fifth {
			/* border: solid 1px rgb(197, 206, 197); */
			color: rgb(242, 250, 240);
			font-size: medium;

			padding: 5px;
		}
	}
}
.lit-radio {
	margin: 3px;
}
.lit-candidate-info-header {
	width: 100%;
	margin: auto;
	margin-top: 5px;
	padding-top: 6px;
	text-align: center;

	color: white;
	&__bolder {
		font-family: 'Roboto', Arial, sans-serif;
		font-weight: lighter;
	}
}
.lit-candidate-label {
	/* border: solid 1px red; */
	color: rgb(255, 255, 255);
	font-family: 'Roboto', Arial, sans-serif;
	padding: 10px;
}
.lit-candidate-label-gender {
	/* 	border: solid 1px red; */
	/* background-color: white; */
	align-content: center;
	color: white;
	padding: 0.5em;
	.lit-options-gender {
		padding: 10px;
		background-color: white;
		border: thin white;
		border-radius: 3px;
		font-size: medium;
		color: black;
		height: 65%;
	}
}
.lit-candidate-label-grade {
	/* border: solid 1px red; */
	/* background-color: white; */
	align-content: left;
	padding: 0.5em;
	margin-left: 8px;
	color: white;
}

input[type='date'] {
	background-color: transparent;
	border: none;
	outline: none;
	color: rgb(6, 6, 95);
	font-size: 17px;
	font-family: 'Roboto Mono', monospace;
	letter-spacing: 1px;
	margin: auto;
	display: block;
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	min-height: 1.2em;
}

.lit-input__field-trinn {
	border: none;
	border-radius: 3px;
	padding: 10px 20px;
	font-size: 18px;
	display: block;
	font-family: 'Roboto', Arial, sans-serif;
	width: 100%;
	box-sizing: border-box;
}

input[type='text'] {
	font-size: 16px;
	font-family: 'Roboto', Arial, sans-serif;
}
input[type='email'] {
	font-size: 16px;
}
.lit-candidate-pin {
	color: black;
	font-size: large;
	letter-spacing: 1.5px;
	font-weight: bold;
}
.lit-input__field-language-inputs {
	border: solid 1px white;
	width: 40%;
	display: grid;
}
.lit-test__heading {
	padding: 3px;
	/* border: solid 1px red; */
	width: 100%;
}
.lit-btn-submit-from {
	width: 20%;
	margin: auto;
	padding: 0.5em;

	.bg-primary-dark {
		background-color: rgb(173, 198, 199);
		border: solid white 2px;
		border-radius: 2px;
		font-size: 0.9rem;

		max-width: 200px;
	}
	.bg-primary-glow {
		background-color: rgb(15, 163, 82);
		border: solid white 2px;
		border-radius: 2px;
		font-size: 0.9rem;
		max-width: 200px;
		color: white;
		animation: glowing 1200ms 1;
	}
}

.lit-modal__continent {
	background: transparent;
	padding: 10px;
	max-width: 70%;
	margin: 2% auto;
	animation: slideUp 0.7s;
	color: white;
}
.lit-modal-candidate {
	background-color: rgba(0, 0, 0, 0.5);
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	height: 100vh;
	width: 100%;
	overflow-x: hidden;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	z-index: $zModal;
	animation: fade 0.5s;
}

.lit-btn_grid {
	margin-top: 18px;
	display: flex;
}
.lit-custom-header_grid {
	padding-top: 4px;
	display: flex;
	justify-content: center; /* 
	background-color: #0da9e9; */

	bottom: black;
	/* border: 1px solid; */
	height: 45px;
	font-family: 'Roboto', Arial, sans-serif;
	color: white;
	margin-left: 3px;
}
.lit-custom_grid {
	display: flex;
	background-color: white;
	color: black;
	border: solid 1px;
	font-family: 'Roboto', Arial, sans-serif;
	margin-top: 1px;
}
.lit-custom_grid-active {
	display: flex;
	background-color: #ebeef1;
	color: black;
	font-family: 'Roboto', Arial, sans-serif;
	margin-top: 1px;
	transition: 0.5s;
	animation: fade 0.5s;
}
.lit-custom-header-wrapper {
	display: flex;
	min-width: 1119px;
	float: left;
	margin-left: 2px;
}

.custom_table-header-cell {
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	min-width: 183px;
}
.custom_table-cell {
	text-align: center;
	min-width: 180px;
	justify-content: center;
	align-items: center;
	margin-right: auto;
	word-wrap: break-word;
	max-width: 170px;
	padding: 8px;
	padding-top: 15px;
}
.lit_table-wrapper {
	padding-left: 5px;
	padding-top: 5px;
	padding-right: 5px;
	padding-bottom: 5px;
}
.custom_checkBox_header-cell {
	display: flex;
	padding: 3px;
	justify-content: left;
	align-items: left;
}

.custom_checkBox-cell {
	display: flex;
	padding: 3px;
	justify-content: center;
	align-items: center;
}
.custom_btn-cell {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	padding: 5px;
	.bg-primary5 {
		height: 40px;
		float: right;
		background-color: rgb(17, 168, 85);
		width: 130px;
		color: white;
	}
	.bg-primary5-active {
		height: 40px;
		/* float: right; */
		background-color: rgb(202, 20, 14);
		width: 130px;
		color: white;
	}
	.bg-primary5:hover {
		background-color: rgb(24, 157, 219);
	}
}
.lit-individual-test {
	display: flex;
	border: solid rgb(142, 177, 211);
	border-width: 0px 1px 1px 1px;
	background-color: white;
	min-height: 60px;
	padding: 5px;
	height: 100%;
	transition: 5s;
	animation: fade 0.5s;
	border-bottom-left-radius: 11px;
	border-bottom-right-radius: 11px;
}
.lit-test-status {
	float: left;
}

.lit-see-result-fixed-width {
	justify-content: center;
	white-space: nowrap;
}
.lit-View_tests {
	margin-top: 18px;
	display: flex;

	.lit-dropdown_filter {
		padding: 2px;
		.lit-input__field {
			margin-top: 2px;
			width: 200px;
			height: 40px;
		}
	}
	.Select_group-function {
		float: right;
		margin-left: 20px;
		padding: 2px;
		.lit-input__field {
			margin-top: 2px;
			width: 200px;
			height: 40px;

			border: solid rgb(28, 138, 189);
			border-width: 0.1em;
		}
	}
}

.lit-input__groupname-label {
	margin-top: 30px;
	margin-bottom: 20px;
	margin-right: 10px;
}

.lit-new-test {
	&__wrapper {
		min-width: 350px;
	}
}
.lit-deleteAll {
	display: flex;
	margin-top: 10px;
	justify-content: center;
}

.lit-menu-item-headings {
	display: inline-block;
	margin-left: 10px;
}
.lit-input_checkbox {
	transform: scale(1.5);
}
.delete-olderTests {
	margin-left: 10px;
}
.bg-negative2 {
	background-color: rgb(211, 29, 29);
	color: white;
	margin-left: 7px;
	box-shadow: 0 0 13px 3px rgb(185, 185, 185);
}
.bg-secondary-green {
	background-color: rgb(58, 143, 24);
	color: white;
	/* animation: glowing 2300ms infinite; */
}
.bg-primary2 {
	background-color: rgb(226, 46, 46);
	color: white;
	animation: glowing 1300ms infinite;
}
.bg-primary2-disbaled {
	background-color: rgb(228, 26, 60);
	color: white;
}

.bg-primary2:hover {
	background-color: rgb(228, 24, 24);
	color: white;
	opacity: 5;
}
.bg-primary3 {
	margin-left: 10px;
	background-color: rgb(71, 189, 243);
	color: white;
}
.bg-primary6 {
	margin-left: 10px;
	background-color: transparent;
	height: 40px;
	margin-top: -4px;
	border: solid thin #d6d2d2;
	color: white;
}
.bg-primary4 {
	margin-left: 10px;
	background-color: rgb(243, 71, 80);
	color: white;
}
.bg-primary5 {
	margin-left: 10px;
	background-color: rgb(43, 133, 25);
	color: white;
}
.bg-primary3:hover {
	margin-left: 10px;
	background-color: rgb(24, 157, 219);
	color: white;
}
.bg-primary6:hover {
	margin-left: 10px;
	border-width: 0.1em;
	color: white;
}
.lit-tf_search {
	::placeholder {
		color: rgb(83, 66, 66);
		opacity: 1;
	}
	box-shadow: 0 0 3px 1px rgb(221, 223, 222);
	border: none;
	margin-left: 10px;
	height: 38px;
	width: 155px;
	background-color: white;
	color: black;
	font-size: 14px;
	text-align: center;
}
.lit-main-candidates-contianer {
	background-color: rgba(18, 95, 139, 0.932);
	border: solid 1px rgba(18, 95, 139, 0.932);
	border-radius: 12px;
	min-width: 1124px;
	padding: 3px;
	margin-top: 8px;
}

.lit-tf_className {
	display: inline-block;
	align-items: right;
	border: solid rgb(115, 201, 241);
	border-width: 0.1em;
	border-radius: 3px;
	margin-top: 10px;
	margin-left: 5px;
	margin-right: 10px;
	height: 40px;
	width: 200px;
	background-color: white;
	color: black;
	font-size: 15px;
	text-align: left;
}
.lit-select_className {
	display: inline-block;
	align-items: right;
	border: solid rgb(115, 201, 241);
	border-width: 0.1em;
	border-radius: 3px;
	margin-top: 10px;
	margin-left: 5px;
	margin-right: 10px;
	height: 40px;
	width: 200px;
	background-color: white;
	color: black;
	font-size: large;
	text-align: center;
}

.bg-primary2:disabled,
.bg-primary2[disabled] {
	border: 1px solid #ffffff;
	background-color: #d6d2d2;
	color: #faf8f8;
}
.bg-primary3:disabled,
.bg-primary3[disabled] {
	background-color: rgba(180, 223, 242, 255);
	color: #faf8f8;
}
.lit_confirm-cost {
	margin-top: 10px;
}

.lit-new-subscription {
	border-radius: $borderRadius;
	display: inline-block;
	vertical-align: top;
	max-width: 700px;
	width: 100%;
	padding: $space-m $space-lg;
	/* margin-bottom: $space; */
	text-align: left;
	background-color: $white;
	box-shadow: $boxShadow1;
	min-width: 340px;

	&__pricing {
		animation: fade 1s;
		& .pricing__header {
			margin-bottom: $space-sm;
		}
		& .pricing__table {
			font-size: $small;
			margin-bottom: 1em;
			& .pricing__item {
				padding-right: $space-sm;
				font-weight: bold;
			}
		}
	}

	&--subscription {
		margin-top: $space-lg;
	}

	&__description {
		a {
			color: royalblue;
		}
	}

	@media screen and (max-width: 1200px) {
		& {
			margin-top: $space-lg;
		}
	}
}
.tickmark {
	font-weight: bold;
	color: green;
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
		sans-serif;
	-ms-transform: scaleX(-1) rotate(-35deg);
	-webkit-transform: scaleX(-1) rotate(-35deg);
	transform: scaleX(-1) rotate(-35deg);
	min-width: 5%;
}
.crossmark {
	font-family: arial;
	font-weight: bold;
	color: red;
	min-width: 5%;
}
.continue {
	font-family: arial;
	font-weight: bold;
	color: rgb(29, 156, 18);
	min-width: 5%;
}
