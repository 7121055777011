@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');

.lit-customLogin {
	margin: auto;
	width: 90%;
	border-radius: 15px;
	padding: 10px;
	margin-top: 30px;

	.lit-customLogin-title {
		color: white;
	}

	&__wrapper {
		border: solid rgb(233, 230, 230) thin;
		background: linear-gradient(90deg, #0d4f6e, #91751b);
		border-radius: 20px;
		box-shadow: 0 0 6px 1px rgb(10, 7, 7);
		padding: 50px;
		margin: auto;
		position: relative;

		width: 450px;
		box-shadow: 0px 0px 24px #5c5696;
	}
	.lit-screen__content {
		z-index: 1;
		position: relative;
		height: 70%;
		border: solid rgb(141, 129, 63) thin;
		box-shadow: 0px 0px 24px #91751b;
		border-radius: 10px;
		padding: 20px;
	}
	.lit-screen__background {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
		-webkit-clip-path: inset(0 0 0 0);
		clip-path: inset(0 0 0 0);
	}

	//background-color: rgba(255, 255, 255, 0.938);
	//width: 100%;
	//height: 100%;

	.lit-invlaid-status-container {
		width: 79%;
		margin: auto;
		background-color: yellow;
		color: white;
		margin-bottom: -1em;
		border: 1 thin yellow;
		border-radius: 1rem;
		.lit-invlaid-note {
			color: rgb(207, 25, 25);
			text-align: center;
		}
	}
	.lit-valid-change-container {
		width: 79%;
		margin: auto;
		background-color: cyan;

		margin-bottom: -1em;
		border: solid 1px rgb(68, 107, 8);
		border-radius: 1rem;
		.lit-valid-change {
			color: green;
			text-align: center;
		}
	}
	.lit-valid-timeout-container {
		justify-content: center;
		margin: auto;
		padding: 2px;
		background-color: rgb(241, 243, 112);
		color: red;
		box-shadow: 0 0 13px 3px rgb(185, 185, 185);

		.lit-valid-timeout {
			color: red;
			text-align: center;
			font-size: 18px;
		}
	}
	.lit-reset-info-container {
		width: 90%;
		margin-top: 2em;
		margin-bottom: 1em;
		border: thin 3px yellow;
		border-radius: 1rem;
		.lit-reset-info {
			font-size: 17px;
			color: rgb(0, 250, 250);
		}
	}
	.login__icon {
		position: absolute;
		top: 30px;
		color: #7875b5;
	}
	.lit-customLogin-wrapper-self {
		margin: auto;
		margin-top: 10%;
		width: 50%;
		border: 5px solid #1c7592;
		border-radius: 15px;
		padding: 2.5rem;
		background-color: #1d6c85;
		min-height: 200px;
		min-width: 400px;

		.lit-customLogin-title {
			color: white;
		}
	}

	.lit-customPin-wrapper {
		margin: auto;
		margin-top: 10%;
		width: 100%;
		border: 5px solid #00080a;
		border-radius: 1rem;
		padding: 2.5rem;
		background-color: #2ca8ce;
		min-height: 200px;
		min-width: 400px;
		.lit-customLogin-title {
			color: white;
		}
	}
	.lit-pin-instrucitons {
		padding: 0.1em;
		word-wrap: break-word;
		width: 70%;
		color: white;
	}
	.lit-customLogin-form-wrapper {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		min-width: 200px;
		padding-bottom: 10px;
		/* background-color: rgb(119, 133, 170); */
		color: black;
	}
	.lit-customPin-inner-content {
		margin: auto;
	}
	.lit-customLogin-inner-content {
		margin: auto;
		width: 70%;
	}
	.lit-compound-fields {
		margin: 1px;
	}
	.lit-compound-pin-fields {
		margin: auto;
		width: 60%;

		padding: 10px;
	}
	.lit-label {
		display: block;
		margin: auto;
		padding: 10px 0;
		min-width: 90%;
		color: white;
		box-sizing: border-box;

		font-family: Raleway, sans-serif;
	}
	.lit-label-pin {
		.customLogin-pin-tf {
			min-width: 50%;
			margin-top: 0.5rem;
			min-height: 2rem;

			border-radius: 10px;
			border: thin solid rgb(172, 34, 34);
			border-radius: 20px;
			margin: 5px;
			padding-left: 1.2em;
		}
	}
	.lit-login__icon {
		position: absolute;
		top: 65px;
		left: 25px;
		color: #044275;
	}
	.lit-label-usr {
		.customLogin-user-tf {
			/* border: none;
			border-bottom: 2px solid #d1d1d4;
			background: none;
			padding: 10px;
			color: white;
			background-color: transparent;
			font-weight: 700;
			width: 75%;
			transition: 0.2s;
			font-family: Raleway, sans-serif; */

			min-width: 100%;
			margin-top: 0.5rem;
			min-height: 2rem;
			border: thin solid rgb(114, 77, 77);
			border-radius: 8px;
			background-color: rgb(237, 245, 248);
			box-shadow: 0.2rem 0.8rem 1.6rem #292835;
			padding-left: 2em;
		}

		::placeholder {
			/* Chrome, Firefox, Opera, Safari 10.1+ */
			color: rgb(194, 197, 185);

			font-family: Raleway, sans-serif;
		}
	}

	.lit-lastName__icon {
		position: absolute;
		top: 142px;
		left: 25px;
		z-index: 10001;
		color: #044275;
	}
	.lit-phone__icon {
		position: absolute;
		top: 222px;
		left: 25px;
		z-index: 10001;
		color: #044275;
	}
	.lit-psw__icon {
		position: absolute;
		top: 145px;
		left: 25px;
		z-index: 10001;
		color: #044275;
	}
	.lit-psw-reg__icon {
		position: absolute;
		top: 302px;
		left: 25px;
		z-index: 10001;
		color: #044275;
	}
	.lit-psw-confirm__icon {
		position: absolute;
		top: 382px;
		left: 25px;
		z-index: 10001;
		color: #044275;
	}
	.lit-psw-confirm-low__icon {
		position: absolute;
		top: 403px;
		left: 25px;
		z-index: 10001;
		color: #044275;
	}
	.lit-invalid-email-note {
		max-width: 100%;
		margin-top: -0.3rem;
		font-size: 16px;
		word-wrap: break-word;
		color: #da0000;
		text-align: center;
	}
	.lit-psw-new__icon {
		position: absolute;
		top: 64px;
		left: 25px;
		z-index: 10001;
		color: #044275;
	}
	.lit-psw-new-confirm__icon {
		position: absolute;
		top: 153px;
		left: 25px;
		z-index: 10001;
		color: #044275;
	}
	.lit-psw-new-confirm-str__icon {
		position: absolute;
		top: 173px;
		left: 25px;
		z-index: 10001;
		color: #044275;
	}

	.lit-label-psw {
		.customLogin-psw-tf {
			/* border: none;
			border-bottom: 2px solid #d1d1d4;
			background: none;
			padding: 10px;
			color: white;
			font-weight: 700;
			width: 75%;
			transition: 0.2s;
			font-family: Raleway, sans-serif; */
			border: thin white;
			min-width: 100%;
			background-color: rgb(237, 245, 248);
			margin-top: 0.5rem;
			min-height: 2rem;
			border: thin white;
			z-index: 10000;
			border-radius: 8px;
			box-shadow: 0.2rem 0.8rem 1.6rem #292835;

			padding-left: 1.8em;
		}

		::placeholder {
			/* Chrome, Firefox, Opera, Safari 10.1+ */
			color: rgb(194, 197, 185);
			opacity: 1; /* Firefox */
			font-family: Raleway, sans-serif;
		}

		/* .customLogin-psw-tf:active,
		.customLogin-psw-tf:focus,
		.customLogin-psw-tf:hover {
			outline: none;
			border-bottom-color: #b1e02d;
		} */
	}
	.Mui-focused {
		border: thin white;
		min-width: 100%;
		background-color: rgb(237, 245, 248);
		margin-top: 0.5rem;
		min-height: 2rem;
		border: thin white;
		z-index: 10000;
		border-radius: 8px;
		box-shadow: 0.2rem 0.8rem 1.6rem #292835;

		padding-left: 1.8em;
	}
	.lit-reset-psw-container {
		max-height: 40px;
		height: 40px;
		margin-top: -20px;
		padding: 10px;
		color: white;
		margin-bottom: -1em;
		border: 1 thin yellow;
		border-radius: 1rem;
		.lit-reset-psw {
			color: white;
			text-align: center;
		}
		.lit-reset {
			text-indent: 1rem;
			color: cyan;
			text-decoration: underline;
		}
		.lit-reset:hover {
			cursor: pointer;
		}
	}
	.lit-btn-submit-wrapper {
		text-align: center;
		width: 300px;
		.lit-btn-submit {
			color: black;
			background: #fff;
			font-size: 14px;
			margin-top: 30px;
			padding: 16px 20px;
			border-radius: 26px;
			border: 1px solid #d4d3e8;
			text-transform: uppercase;
			font-weight: 700;
			display: flex;
			align-items: center;
			width: 100%;
			color: #4c489d;
			box-shadow: 0px 2px 2px #5c5696;
			cursor: pointer;
			transition: 0.2s;
		}
		.lit-btn-submit:hover {
			background-color: rgb(252, 252, 7);
			color: blue;
			cursor: pointer;
			border: none;
		}
	}
}

.lit-psw-strength-container {
	display: flex;
	flex-direction: row;
	min-height: 20px;
	width: 100%;
	.lit-str-text {
		margin-top: 3px;
		max-width: 70%;
		margin-left: 5px;
		float: right;
		color: rgb(12, 241, 203);
		font-size: 0.7em;
	}
	.lit-strength-Exhibitor-green {
		width: 95%;
		height: 3px;
		margin-top: 7px;
		background-color: green;
		transition: 0.5s;
		border: thin solid;
		border-radius: 4px;
		border-color: white;
		padding: 2px;
	}
	.lit-strength-Exhibitor-orange {
		margin-top: 7px;
		width: 55%;
		height: 5px;
		background-color: rgb(230, 178, 82);
		bottom: -8px;
		transition: 0.5s;
		border: thin solid;
		border-radius: 4px;
		border-color: white;
		padding: 2px;
	}
	.lit-strength-Exhibitor-red {
		margin-top: 7px;
		width: 35%;
		height: 5px;
		background-color: red;
		bottom: -8px;
		transition: 0.5s;
		border: thin solid;
		border-radius: 4px;
		border-color: white;
		padding: 2px;
	}
}
.lit-signin__icon {
	margin-left: 10px;
}
.lit-log-gram-wrapper {
	margin-bottom: -45px;
	margin-top: 15px;
	text-align: center;
}
.lit-fav-logo {
	margin-top: 20px;
	&__img {
		max-height: 17px;
	}

	&.clickable:hover {
		cursor: pointer;
	}
}
