@import './vars/environment';

.oiw-cols {
	font-size: 0;
	clear: both;
}
.oiw-col {
	font-size: 16px;
	font-size: 1rem;
	display: inline-block;
	vertical-align: top;
	width: 50%;

	&--middle {
		vertical-align: middle;
	}

	&--33 {
		width: 33.33%;
	}
	&--66 {
		width: 66.66%;
	}

	&--auto {
		width: auto;
	}

	&__content {
		width: #{$outerContentWrapperWidth / 2}px;
		max-width: 100%;

		&--align-right {
			float: right;
			width: #{$outerContentWrapperWidth / 2}px;
		}

		&--align-left {
			float: left;
		}
	}
}
