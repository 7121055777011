.lit-sidebar {
	position: fixed;
	left: 0;
	top: 0;
	width: $sidebarWidth;
	height: 100%;
	height: 100vh;
	z-index: $zSidebar;
	color: $white;
	& .lit-logo {
		padding-top: $space-sm;
		height: $headerHeight;
		overflow: hidden;
		box-sizing: border-box;
		margin-bottom: 0;
	}
}
