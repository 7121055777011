@import '../../styles/common';
.lit-lang-opitons-wrapper {
	display: flex;
	margin-top: 10px;
	gap: 2%;
}
.lit-lang-buttons-wrapper {
	display: flex;
	margin-top: 30px;
	gap: 2%;
	.lit-lang-no {
		&__bg {
			padding: 12px;
			background-color: rgb(23, 119, 163);
			color: white;
			font-size: 14px;
			border: solid 2px white;
			border-radius: 10px;
		}
	}
	.lit-lang-de {
		&__bg {
			padding: 12px;
			background-color: rgb(23, 119, 163);
			color: white;
			font-size: 14px;
			border: solid 2px white;
			border-radius: 10px;
		}
	}
	.lit-lang-se {
		&__bg {
			padding: 12px;
			background-color: rgb(23, 119, 163);
			color: white;
			font-size: 14px;
			border: solid 2px white;
			border-radius: 10px;
		}
	}
	.lit-lang-uncommon {
		&__bg {
			padding: 12px;
			background-color: #995994;
			color: white;
			font-size: 14px;
			border: solid 2px white;
			border-radius: 10px;
		}
	}
	.lit-lang-change {
		&__bg {
			padding: 12px;
			background-color: rgb(196, 144, 3);
			color: white;
			font-size: 14px;
			border: solid 2px white;
			border-radius: 10px;
		}
		&__bgO {
			padding: 12px;
			background-color: rgb(75, 148, 26);
			color: white;
			font-size: 14px;
			border: solid 2px white;
			border-radius: 10px;
		}
	}
}
.lit-lang-log {
	margin: 10px;
	td {
		border-bottom: solid 1px #ccc;
		padding: 4px;
	}
}
.inline-btn {
	display: inline-block;
	margin-right: 5px;
}
.lit-modal__ls-continent {
	background: transparent;
	max-width: 70%;
	margin: 2% auto;
	animation: slideUp 0.7s;
	color: white;
}

.lit-modal-stringsCatalog {
	background-color: rgba(32, 31, 31, 0.5);
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	height: 100vh;
	width: 100%;
	overflow-x: hidden;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	z-index: $zModal;
	animation: fade 0.5s;
}
.lit-strings-theader-wrapper {
	color: rgb(24, 15, 15);
	text-align: center;
	margin: auto;
	width: 80%;
	padding: 10px;
}

.lit-strings-info-header {
	width: 100%;
	margin: auto;
	margin-top: 5px;
	padding-top: 3px;
	text-align: center;
	font-weight: bold;
	color: black;
	&__bolder {
		font-family: 'Roboto', Arial, sans-serif;
	}
}
.lit-string-catalog {
	color: black;

	display: block;
	/* border: solid 1px rgb(226, 138, 37); */
	width: 95%;
	margin: auto;
	box-shadow: 0 0 5px 3px rgb(46, 46, 46);
	border: rgba(50, 128, 151, 0.61) solid 1px;
	border-radius: 33px;

	.lit-string-catalog-container {
		/* border: solid 1px rgb(50, 53, 205); */
		width: 100%;
		border: rgba(50, 128, 151, 0.61) solid 1px;
		background: linear-gradient(to bottom left, #f0f9fc, #f0f9fc);
		border-radius: 33px;
		flex-direction: row;
		box-shadow: 0 0 5px 3px rgb(46, 46, 46);

		margin: auto;
	}
}
.lit-strings-theader {
	font-size: 20px;
	color: rgb(27, 27, 37);
	height: 2px;
	line-height: 42px;
	box-shadow: 0 0 4px 3px rgb(46, 46, 46);
}
.lit-strings-tbody {
	font-size: 17px;
	background-color: #d7dcdd;
	box-shadow: 0 4px 3px 3px rgb(46, 46, 46);
}
.lit-lang-catalog {
	margin: 30px;
}

.tickmark-sl {
	font-weight: bold;
	color: rgb(24, 128, 24);
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
		sans-serif;
	-ms-transform: scaleX(-1) rotate(-35deg);
	-webkit-transform: scaleX(-1) rotate(-35deg);
	transform: scaleX(-1) rotate(-35deg);
	font-size: 20px;
}

.crossmark-sl {
	font-family: arial;
	font-weight: bold;
	color: rgb(230, 20, 13);

	font-size: 20px;
}

.edit-sl {
	font-weight: bold;
	color: rgb(24, 128, 24);
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
		sans-serif;
	font-size: 20px;
}

.add-sl {
	font-family: arial;
	font-weight: bold;
	color: rgb(230, 20, 13);

	font-size: 20px;
}

table {
	border-collapse: separate !important;
	border-spacing: 0;
	width: 600px;
	margin: 30px;
}
.lit-list-strings {
	width: 100%;
	border: solid #ccc 1px;
	-moz-border-radius: 6px;
	-webkit-border-radius: 6px;
	border-radius: 6px;
	-webkit-box-shadow: 0 1px 1px #ccc;
	-moz-box-shadow: 0 1px 1px #ccc;
	box-shadow: 0 1px 1px #ccc;
}

.lit-list-strings tr:hover {
	background: #ececec;
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
}

.lit-list-strings td,
.lit-list-strings th {
	border-left: 1px solid #ccc;
	border-top: 1px solid #ccc;
}

.lit-list-strings th {
	background-color: #ececec;
	background-image: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(#f8f8f8),
		to(#ececec)
	);

	-webkit-box-shadow: 0 1px 0 rgba(63, 40, 40, 0.8) inset;
	-moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8) inset;
	box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8) inset;
	border-top: none;
	text-shadow: 0 1px 0 rgba(206, 210, 211, 0.5);
	position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
	position: sticky;
	top: 0;
	z-index: 1; // any positive value, layer order is global
	// any bg-color to overlap
}
.lit-list-strings td:first-child,
.lit-list-strings th:first-child {
	border-left: none;
}
.lit-list-strings th:first-child {
	-moz-border-radius: 6px 0 0 0;
	-webkit-border-radius: 6px 0 0 0;
	border-radius: 6px 0 0 0;
}
.lit-list-strings th:last-child {
	-moz-border-radius: 0 6px 0 0;
	-webkit-border-radius: 0 6px 0 0;
	border-radius: 0 6px 0 0;
}
.lit-list-strings th:only-child {
	-moz-border-radius: 6px 6px 0 0;
	-webkit-border-radius: 6px 6px 0 0;
	border-radius: 6px 6px 0 0;
}
.lit-list-strings tr:last-child td:first-child {
	-moz-border-radius: 0 0 0 6px;
	-webkit-border-radius: 0 0 0 6px;
	border-radius: 0 0 0 6px;
}
.lit-list-strings tr:last-child td:last-child {
	-moz-border-radius: 0 0 6px 0;
	-webkit-border-radius: 0 0 6px 0;
	border-radius: 0 0 6px 0;
}
.lit-close-icon {
	float: right;
	margin: 20px;
	margin-top: -40px;
}
.lit-close-sl-icon {
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
		sans-serif;
	font-weight: normal;
	color: rgb(230, 20, 13);
	border: solid rgb(49, 47, 47) thin;
	font-size: 30px;
	padding: 0 5px 0 5px;
	box-shadow: 0 0 5px 3px rgb(23, 32, 56);
	border-radius: 40%;
}

.lit-close-sl-icon:hover {
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS',
		sans-serif;
	font-weight: normal;
	color: rgb(230, 20, 13);
	border: none;
	font-size: 30px;
	padding: 0 5px 0 5px;
	cursor: pointer;
	box-shadow: 0 0 5px 3px rgb(27, 87, 207);
}

.lit-edistrings {
	width: 90%;
	margin: auto;
	margin-bottom: 30px;
	background: rgb(230, 241, 246);
	color: black;

	padding-left: 30px;
	padding-top: 30px;
	padding-right: 30px;
	padding-bottom: 50px;
	border: solid white thin;
	border-radius: 20px;
	justify-content: center;
	&__change {
		display: flex;
		width: 60%;
		margin: auto;
		gap: 3%;
	}
	span {
		word-wrap: break-word;
		font-size: 20px;
		color: blue;
	}
	.lit-content-area {
		padding: 4px;
		font-size: 20px;
		width: fit-content;
		background: rgb(253, 253, 252);
		border: solid white thin;
		border-radius: 10px;
	}
	.lit-change-btn-group {
		width: 28%;
		padding: 25px;
		display: flex;
		margin: auto;
	}
	.lit-lang-sendChange {
		margin: auto;
		&__bg {
			padding: 12px;
			background-color: rgb(246, 191, 39);
			color: rgb(12, 12, 34);
			font-size: 14px;
			border: solid 2px white;
			border-radius: 10px;
		}
		&__hg {
			padding: 12px;
			background-color: rgb(33, 151, 180);
			color: white;
			font-size: 14px;
			border: solid 2px white;
			border-radius: 10px;
		}
	}
}
.lit-string-change-catalog {
	margin: auto;
	padding: 10px;
}
.lit-title-header-wrapper {
	background-color: rgb(191, 196, 195);
	border: white solid thin;
	border-radius: 20px;
	margin: 10px;
}
.lit-strings-change-header {
	margin-top: 5px;
	text-align: center;
	margin-left: 20px;
	font-weight: bold;
	justify-content: center;
	margin-bottom: auto;
	margin-left: auto;
	margin-right: auto;
	color: rgb(30, 55, 167);

	h3 {
		font-weight: bold;
	}
	&__bolder {
		font-family: 'Roboto', Arial, sans-serif;
	}
}
.lit-string-change-files {
	background-color: rgb(239, 242, 247);
	border: white solid thin;
	border-radius: 20px;
}
.lit-close-icon-wrapper {
	margin-top: 50px;
}

.lit-update-strings {
	width: 90%;
	margin: auto;

	color: black;

	border-radius: 20px;
	justify-content: center;
	&__change {
		display: flex;
		width: 60%;
		margin: auto;
		gap: 3%;
	}
	span {
		word-wrap: break-word;
		font-size: 20px;
		color: blue;
	}
	.lit-content-area {
		padding: 4px;
		font-size: 20px;
		width: fit-content;
		background: rgb(253, 253, 252);
		border: solid white thin;
		border-radius: 10px;
	}
	.lit-change-btn-group {
		padding: 5px;
		display: flex;
		margin-top: auto;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 10px;
	}
	.lit-lang-sendChange {
		margin: auto;
		&__bg {
			padding: 12px;
			background-color: rgb(246, 191, 39);
			color: rgb(12, 12, 34);
			font-size: 14px;
			border: solid 2px white;
			border-radius: 10px;
		}
		&__hg {
			padding: 12px;
			background-color: rgb(33, 151, 180);
			color: white;
			font-size: 14px;
			border: solid 2px white;
			border-radius: 10px;
		}
	}
}
.lit-short-msg {
	color: green;
	border: solid white thin;
	border-radius: 6px;
	width: 10%;
	margin: auto;
	opacity: 0;
	text-align: center;
	opacity: 13;
	transition: transform 4s, opacity 3s;
	font-size: 22px;
	background-color: greenyellow;
	box-shadow: 0 0 13px 3px rgb(10, 7, 7);
}
