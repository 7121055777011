@import 'colors';
@import 'breakpoints';
@import 'environment';

@mixin uncollapse {
	&:before,
	&:after {
		content: "\00a0";
		display: block;
		overflow: hidden;
		height: 0;
	}
}

@mixin clearfix {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}

@mixin roundImage($size: 70px) {
	width: $size;
	height: $size;
	border-radius: 50%;
	margin: 0 auto;
	@include bgImage;
}

@mixin bgImage {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

@mixin noFocus {
	&:focus {
		outline: 0;
	}
}

@mixin box {
	padding: $space $space-lg;
	border-radius: $borderRadius;

	@include mobile {
		padding: $space $space;
	}
}

@mixin activeState {
	cursor: pointer;
	&:active {
		transform: translateY(1px);
	}
}

@mixin link($color: $linkColor, $hoverColor: lighten($linkColor, 10%), $activeColor: darken($hoverColor, 20%)) {
	color: $color;
	cursor: pointer;

	@include transitions {
		transition: color 0.12s;

		&:hover {
			color: $hoverColor;
		}
	}

	&:active {
		transition: none;
		color: $activeColor;
	}
}

@mixin wrapper($width: 940) {
	max-width: #{$width}px;
	margin-left: auto;
	margin-right: auto;

	@media (max-width: #{$width + $wrapperCollapseMargin * 2}px) {
		margin-left: #{$wrapperCollapseMargin}px;
		margin-right: #{$wrapperCollapseMargin}px;
	}

	@if (($width + $wrapperCollapseMargin * 2) > $breakpointMobileThin) {
		@media (max-width: #{$breakpointMobileThin}px) {
			margin-left: #{$wrapperCollapseMobileMargin}px;
			margin-right: #{$wrapperCollapseMobileMargin}px;
		}
	}
}

@mixin unwrap {
	margin-left: -#{$wrapperCollapseMargin}px;
	margin-right: -#{$wrapperCollapseMargin}px;

	@media (max-width: #{$breakpointMobileThin}px) {
		margin-left: -#{$wrapperCollapseMobileMargin}px;
		margin-right: -#{$wrapperCollapseMobileMargin}px;
	}
}

@mixin preventChildMargin {
	> :first-child,
	> :first-child > :first-child {
		margin-top: 0;
	}

	> :last-child,
	> :last-child > :last-child {
		margin-bottom: 0;
	}
}

@mixin stagger($animation: fade, $count: 6, $offset: 0.2, $initialDelay: 0.3) {
	@for $i from 1 to $count {
		&:nth-child(#{$i}) {
			animation: $animation #{$initialDelay + ($i * $offset)}s;
		}
	}
}

@mixin bgSection($padding: $space-section 0) {
	position: relative;

	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		@include bgImage;

		&--darken {
			background-color: #000;
			opacity: 0.5;
		}

		&--overlay {
			background: linear-gradient(to bottom right, #bd1f68, rgb(80, 156, 171));
			opacity: 0.6;
		}
	}

	&__fg {
		position: relative;
		z-index: 1;
		padding: $padding;
		color: $textColorBright;
	}
}

@mixin scrollbar($color: $primary, $bg: rgba(0,0,0,0.2)) {
	@include transitions {
        &::-webkit-scrollbar {
            width: 7px;
            height: 7px;
        }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
            background-color: $bg;
            border-radius: $borderRadius;
        }
        &::-webkit-scrollbar-thumb {
            background-color: $color;
            border-radius: $borderRadius;
            transition: background-color 0.2s;

            &:hover {
                background-color: darken($color, 5%);
            }
        }
    }
}

@mixin placeholder($color: rgba($textColorBright, 0.3)) {
	&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		color: $color;
	}
	&::-moz-placeholder { /* Firefox 19+ */
		color: $color;
	}
	&:-ms-input-placeholder { /* IE 10+ */
		color: $color;
	}
	&:-moz-placeholder { /* Firefox 18- */
		color: $color;
	}
}

@mixin modal($islandWidth: 960px, $islandBackground: $white, $backdropColor: rgba(0,0,0,0.5), $margin: 100px auto) {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	height: 100vh;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	background-color: $backdropColor;
	z-index: $zModal;

	&__island {
		max-width: $islandWidth;
		margin: $margin;
		background: $islandBackground;
		padding: $space-lg $space-lg;
		box-sizing: border-box;
		position: relative;
	}

	&__close {
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(-50%, -50%);
	}
}
