.inline-button__wrapper {
	display: flex;
	margin-top: 20px;
	.lit-btn:not(:first-child) {
		margin-left: 10px;
	}
}
.lit_txtf_users {
	background-color: #ffffff;
	margin-left: 20px;
	margin-right: 20px;
	height: 35px;
	border-width: thin;
	box-sizing: border-box;
}
.lit_lbl_users {
	display: inline-block;
	position: relative;

	background-color: transparent;
	box-sizing: border-box;
}
.lit_cb_users {
	display: inline-block;

	margin-left: 10px;
	margin-right: 20px;
	width: 1.25em;
	height: 1.25em;
	background: #fff;
	border-radius: 4px;
}
.lit-input__field {
	background-color: white;
}
.lit-lang {
	margin-top: 5px;
	margin-bottom: 5px;
}
.lit-btn-lang-expander {
	width: 400px;
	font-size: large;
	border: none;
	height: 40px;
}
.lit-bg-secondary {
	background-color: rgb(28, 106, 173);
	color: white;
	padding: 10px;
	//text-align: left;
}
.subs_bg-secondary {
	display: block;
	margin-top: 5px;
	margin-left: 38%;
	background-color: rgb(9, 74, 216);
	color: white;
	padding: 10px;
}
/* .lit-bg-secondary::after {
	content: '\25be';
	float: right;
	transform: scale(1.7);
} */
.lit-img-button {
	background-color: transparent;
	margin-left: 10px;
	border: none;
	box-sizing: border-box;
}
.lit-img-button:hover {
	background-color: white;
}
.moveleft {
	text-align: right;
}

.main_production {
	float: left;
	overflow: hidden;
}

.lit_label-delete {
	display: inline-block;
	margin-top: 5px;

	text-align: right;
}
.lit_label-delete:hover {
	font-weight: bolder;
	cursor: pointer;
	text-decoration: underline;
	text-decoration-color: red;
}
.lang_label-delete {
	font-size: 20px;
	color: red;
}

.lit_subscriptions-wrapper {
	display: flex;
	background-color: rgb(252, 253, 253);

	margin-top: 5px;
}
.main_norming {
	float: right;
	overflow: hidden;
	margin-left: 50px;
}
.basic_subscription {
	float: right;
	overflow: hidden;
	margin-left: 50px;
}

.lit-lang_container {
	margin-top: 3px;
	font-weight: bold;
	font-size: 22px;
	background: #fff;
}
.one_main-options {
	margin-top: 7px;
}

.two-main_options {
	margin-top: 5px;
}
.three-main_options {
	margin-top: 5px;
}
.lang_label {
	font-size: 20px;
	color: black;
}
.user_checkbox {
	margin-top: 10px;
	transform: scale(1.4);
}
.lit-tests-subs_label {
	margin-left: 3px;
	color: black;
}
.lit_assign-permission {
	display: inline-block;
}
.assign-permission_bg-secondary {
	height: 45px;
	background-color: #43bbec;
	color: white;
	margin-top: 5px;
	padding-bottom: 7px;
	text-align: left;
	text-decoration: whitesmoke;
}
.add-language_bg-secondary {
	height: 45px;
	background-color: #43bbec;
	color: white;
	margin-top: 10px;
	padding-bottom: 10px;
	margin-left: 15px;
	text-align: left;
	text-decoration: whitesmoke;
}
.lit_delete-permission {
	display: inline-block;
}
.delete-permission_bg-secondary {
	height: 45px;
	background-color: #c41212;
	color: white;
	margin-top: 5px;
	padding-bottom: 7px;
	text-align: left;
	text-decoration: whitesmoke;
	margin-left: 7px;
}
.lit_language-dropdown {
	display: inline-block;
	.lit_language-dropdown {
		display: inline-block;
		width: 50%;
		height: 50%;
		float: left;
	}
	.lit-add_language {
		display: inline-block;
		margin-left: 170px;
		height: 50%;
		float: right;
		margin-top: -40px;
	}
}
.checkout {
	border-bottom: solid rgba(29, 106, 173, 255);
	padding: 1%;
	&__expanded {
		opacity: 0;
		animation: registerExpand 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.05);
		animation-fill-mode: forwards;
		animation-delay: 0.1s;
		transform-origin: top;
	}
}
