.printable {
	display: none !important;
}
.non-printable {
	z-index: 1;
	position: relative;
}
.detail {
	position: absolute;
	top: 30px;
	left: 90px;
	right: 0;
	bottom: 0;
	width: calc(100% - 90px);
	z-index: 0;
}
@media print {
	.non-printable {
		display: none !important;
	}
	.printable {
		display: block !important;
	}

	.chartjs-size-monitor-expand{
		display:none;
	}
}
