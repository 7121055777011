$space: 20px;
$space-xsm: ($space / 4);
$space-sm: ($space / 2);
$space-m: ($space * 1.5);
$space-lg: ($space * 2);
$space-lgp: ($space * 3);
$space-xlg: ($space * 4);
$space-section: 150px;

$wrapperCollapseMargin: 20;
$wrapperCollapseMobileMargin: 10;

$contentMargin: 1.5em;
$contentMarginLarge: 3em;
