.lit-circle {
	width: 90px;
	height: 90px;
	display: inline-block;
	position: relative;
	user-select: none;
}

.lit-circle__interactive,
.lit-circle__static {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.lit-circle__static {
	border-radius: 50%;
	border: 6px solid;
	width: 70px;
	height: 70px;
	margin: 4px;
}

.lit-circle__text {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	margin: 0;
	font-weight: bold;
	font-size: 1.4em;
	cursor: default;
}
